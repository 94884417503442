import { GoogleSearchScreenshotsState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';
import { IGoogleSearchScreenshot, IGoogleSearchState } from '@/interfaces/google-search';


export const mutations = {
  setGoogleSearchFinished(state: GoogleSearchScreenshotsState, payload: boolean) {
    state.finished = payload;
  },
  setGoogleSearchState(state: GoogleSearchScreenshotsState, payload: IGoogleSearchState) {
    state.state = payload;
  },
};

const { commit } = getStoreAccessors<GoogleSearchScreenshotsState, State>('');

export const commitSetGoogleSearchFinished = commit(mutations.setGoogleSearchFinished);
export const commitSetGoogleSearchState = commit(mutations.setGoogleSearchState);
