import { SiteParserTaskState } from './state';
import { apiUrl } from '@/env';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';
import { ISiteParserTask, ISiteParserOneTaskStat, ISiteParserTasksStatData } from '@/interfaces/site-parser';


export const mutations = {
  setSiteParserTasks(state: SiteParserTaskState, payload: ISiteParserTask[]) {
    // добавляем флаг isExporting; его пока нет в PG
    // если isExporting = true, иконка загрузки файлов заблокирована
    payload.forEach((item) => {
      item.isExporting = state.tasks.find((task) => task.id === item.id)?.isExporting;
      item.isDownloading = state.tasks.find((task) => task.id === item.id)?.isDownloading;
      if (item.status === 'ACTIVE') {
        const taskActive = state.tasks.find((t) => t.id === item.id);
        if (taskActive && taskActive.stats) item.stats = taskActive.stats;
      }
      if (item.isExporting) item.status = 'EXPORT';
      else if (item.isDownloading) item.status = 'DOWNLOAD';
    });
    state.tasks = payload;
  },
  setSiteParserTask(state: SiteParserTaskState, payload: ISiteParserTask) {

    // const newList: ISiteParserTask[] = state.yt_tasks.slice();
    // const index = newList.findIndex(item => item.id === payload.id);
    // if (index !== -1) {
    //     newList[index] = payload;
    //     state.yt_tasks = newList;
    // };

    // самый быстрый вариант
    const newList: ISiteParserTask[] = state.tasks.slice();
    for (let i = 0; i < newList.length; i++)
      if (newList[i].id === payload.id) {
        newList[i] = payload;
        break;
      }
    state.tasks = newList;
  },

  // изменяем флаг isExporting
  setSiteParserTaskIsExporting(state: SiteParserTaskState, payload: { id: number, isExporting: boolean}) {
      const task = state.tasks.find((item: ISiteParserTask) => item.id === payload.id);
      if (task) task.isExporting = payload.isExporting;
  },

  // изменяем флаг isDownloading
  setSiteParserTaskIsDownloading(state: SiteParserTaskState, payload: { id: number, isDownloading: boolean}) {
      const task = state.tasks.find((item: ISiteParserTask) => item.id === payload.id);
      if (task) task.isDownloading = payload.isDownloading;
  },

  setSiteParserTaskStatus(state: SiteParserTaskState, payload: { id: number, status: string}) {
      const task = state.tasks.find((item: ISiteParserTask) => item.id === payload.id);
      if (task) task.status = payload.status;
  },

  setSiteParserTasksStat(state: SiteParserTaskState, payload: ISiteParserTasksStatData) {
    if (payload.tasks)
      state.tasks = state.tasks.map((t: ISiteParserTask) => {
        const taskStat = payload.tasks.find((s: ISiteParserOneTaskStat) =>  t.id === s.task_id);
        if (taskStat) {
          t.status = taskStat.status;
          t.datetime_end = taskStat.datetime_end;

          const prev = t.stats?.prev;
          const prev_time = t.stats?.prev_time;

          t.stats = taskStat.stats;

          // записыаем стартовые значения количества найденных данных и времени
          // для расчета времени до завершения парсинга
          if (t.stats) {
            t.stats.prev = prev;
            t.stats.prev_time = prev_time;

            const curTime = new Date();

            if (t.stats.found_count > 0) {
              if (t.stats.prev === undefined)
                t.stats.prev = t.stats.found_count;

              if (t.stats.prev_time === undefined)
                t.stats.prev_time = curTime;
            }
          }

          if (taskStat.result)
            t.result = `${apiUrl}/api/v1/plugs-checker-tasks/result/${taskStat.result}`;
        }
        return t;
      });
  },
  setSiteParserDomains(state: SiteParserTaskState, payload: string[]) {
    state.domains = [...payload];
  },
};

const { commit } = getStoreAccessors<SiteParserTaskState, State>('');

export const commitSetSiteParserTask = commit(mutations.setSiteParserTask);
export const commitSetSiteParserTasks = commit(mutations.setSiteParserTasks);
export const commitSetSiteParserTasksStat = commit(mutations.setSiteParserTasksStat);
export const commitSetSiteParserTaskIsExporting = commit(mutations.setSiteParserTaskIsExporting);
export const commitSetSiteParserTaskIsDownloading = commit(mutations.setSiteParserTaskIsDownloading);
export const commitSetSiteParserTaskStatus = commit(mutations.setSiteParserTaskStatus);
export const commitSetSiteParserDomains = commit(mutations.setSiteParserDomains);
