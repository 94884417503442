import { api } from '@/api';
import { ActionContext } from 'vuex';
import { State } from '../state';
import { getStoreAccessors } from 'typesafe-vuex';
import {
  commitClearBrowserFiles,
  commitDelBrowserFile,
  commitSetBrowserFiles,
} from './mutations';
import { dispatchCheckApiError } from '../main/actions';

import axios from 'axios';
import { IFile } from '@/interfaces/file-browser';
import { BrowserState } from './state';

type MainContext = ActionContext<BrowserState, State>;

export const actions = {
  async actionGetBrowserFiles(context: MainContext, workDir: string) {
    try {
      const { data } = await api.getBrowserFiles(context.rootState.main.token, workDir);
      const convertedData: IFile[] = data.map((el: IFile) => ({
        id: el.id,
        filename: el.filename,
        modified_date: el.modified_date,
        year: +el.modified_date.split('-')[0] || undefined,
        month: +el.modified_date.split('-')[1] || undefined,
      }));
      commitSetBrowserFiles(context, { workDir, files: convertedData });
    } catch (error) {
      if (axios.isAxiosError(error)) await dispatchCheckApiError(context, error);
    }
  },
  async actionGetBrowserFile(context: MainContext, payload: { workDir: string, filename: string }) {
    try {
      return await api.getBrowserFile(context.rootState.main.token, payload.workDir, payload.filename);
    } catch (error) {
      if (axios.isAxiosError(error)) await dispatchCheckApiError(context, error);
    }
  },
  async actionDelBrowserFile(context: MainContext, payload: { workDir: string, filename: string }) {
    try {
      await api.delBrowserFile(context.rootState.main.token, payload.workDir, payload.filename);
      commitDelBrowserFile(context, payload);
    } catch (error) {
      if (axios.isAxiosError(error)) await dispatchCheckApiError(context, error);
    }
  },
  async actionDelBrowserAllFiles(context: MainContext, workDir: string) {
    try {
      await api.delBrowserAllFiles(context.rootState.main.token, workDir);
      commitClearBrowserFiles(context, workDir);
    } catch (error) {
      if (axios.isAxiosError(error)) await dispatchCheckApiError(context, error);
    }
  },
};

const { dispatch } = getStoreAccessors<BrowserState, State>('');

export const dispatchGetBrowserFiles = dispatch(actions.actionGetBrowserFiles);
export const dispatchGetBrowserFile = dispatch(actions.actionGetBrowserFile);
export const dispatchDelBrowserFile = dispatch(actions.actionDelBrowserFile);
export const dispatchDelBrowserAllFiles = dispatch(actions.actionDelBrowserAllFiles);
