import { AvitoTaskState } from './state';
import { apiUrl } from '@/env';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';
import { IAvitoTask, IAvitoOneTaskStat, IAvitoTasksStatData } from '@/interfaces/avito-tasks';


export const mutations = {
  setAvitoTasks(state: AvitoTaskState, payload: IAvitoTask[]) {
    // добавляем флаг isExporting; его пока нет в PG
    // если isExporting = true, иконка загрузки файлов заблокирована
    payload.forEach((item) => {
      item.isExporting = state.tasks.find((task) => task.id === item.id)?.isExporting;
      item.isDownloading = state.tasks.find((task) => task.id === item.id)?.isDownloading;
      if (item.status === 'ACTIVE') {
        const taskActive = state.tasks.find((t) => t.id === item.id);
        if (taskActive && taskActive.stats) item.stats = taskActive.stats;
      }
      if (item.isExporting) item.status = 'EXPORT';
      else if (item.isDownloading) item.status = 'DOWNLOAD';
    });
    state.tasks = payload;
  },
  setAvitoTask(state: AvitoTaskState, payload: IAvitoTask) {

    // const newList: IAvitoTask[] = state.yt_tasks.slice();
    // const index = newList.findIndex(item => item.id === payload.id);
    // if (index !== -1) {
    //     newList[index] = payload;
    //     state.yt_tasks = newList;
    // };

    // самый быстрый вариант
    const newList: IAvitoTask[] = state.tasks.slice();
    for (let i = 0; i < newList.length; i++)
      if (newList[i].id === payload.id) {
        newList[i] = payload;
        break;
      }
    state.tasks = newList;
  },

  // изменяем флаг isExporting
  setAvitoTaskIsExporting(state: AvitoTaskState, payload: { id: number, isExporting: boolean}) {
      const task = state.tasks.find((item: IAvitoTask) => item.id === payload.id);
      if (task) task.isExporting = payload.isExporting;
  },

  // изменяем флаг isDownloading
  setAvitoTaskIsDownloading(state: AvitoTaskState, payload: { id: number, isDownloading: boolean}) {
      const task = state.tasks.find((item: IAvitoTask) => item.id === payload.id);
      if (task) task.isDownloading = payload.isDownloading;
  },

  setAvitoTaskStatus(state: AvitoTaskState, payload: { id: number, status: string}) {
      const task = state.tasks.find((item: IAvitoTask) => item.id === payload.id);
      if (task) task.status = payload.status;
  },

  setAvitoTasksStat(state: AvitoTaskState, payload: IAvitoTasksStatData) {
    if (payload.tasks)
      state.tasks = state.tasks.map((t: IAvitoTask) => {
        const taskStat = payload.tasks.find((s: IAvitoOneTaskStat) =>  t.id === s.task_id);
        if (taskStat) {
          t.status = taskStat.status;
          t.datetime_end = taskStat.datetime_end;

          const queriesPrev = t.stats?.queries.prev;
          const queriesPrevTime = t.stats?.queries.prev_time;

          const adsPrev = t.stats?.ads.prev;
          const adsPrevTime = t.stats?.ads.prev_time;

          t.stats = taskStat.stats;

          // записыаем стартовые значения количества найденных данных и времени
          // для расчета времени до завершения парсинга
          if (t.stats) {
            const curTime = new Date();

            t.stats.queries.prev = queriesPrev;
            t.stats.queries.prev_time = queriesPrevTime;

            if (t.stats.queries.found_count > 0) {
              if (t.stats.queries.prev === undefined)
                t.stats.queries.prev = t.stats.queries.found_count;

              if (t.stats.queries.prev_time === undefined)
                t.stats.queries.prev_time = curTime;
            }

            t.stats.ads.prev = adsPrev;
            t.stats.ads.prev_time = adsPrevTime;

            if (t.stats.ads.found_count > 0) {
              if (t.stats.ads.prev === undefined)
                t.stats.ads.prev = t.stats.ads.found_count;

              if (t.stats.ads.prev_time === undefined)
                t.stats.ads.prev_time = curTime;
            }

          }

          if (taskStat.result)
            t.result = `${apiUrl}/api/v1/avito-tasks/result/${taskStat.result}`;
        }
        return t;
      });
  },

};

const { commit } = getStoreAccessors<AvitoTaskState, State>('');

export const commitSetAvitoTask = commit(mutations.setAvitoTask);
export const commitSetAvitoTasks = commit(mutations.setAvitoTasks);
export const commitSetAvitoTasksStat = commit(mutations.setAvitoTasksStat);
export const commitSetAvitoTaskIsExporting = commit(mutations.setAvitoTaskIsExporting);
export const commitSetAvitoTaskIsDownloading = commit(mutations.setAvitoTaskIsDownloading);
export const commitSetAvitoTaskStatus = commit(mutations.setAvitoTaskStatus);
