import { mutations } from './mutations';
import { getters } from './getters';
import { actions } from './actions';
import { SiteTrafficTaskState } from './state';

const defaultState: SiteTrafficTaskState = {
  tasks: [],
};

export const siteTrafficTaskModule = {
  state: defaultState,
  mutations,
  actions,
  getters,
};
