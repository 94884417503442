import { getStoreAccessors } from 'typesafe-vuex';

import { YtTaskState } from './state';
import { State } from '../state';
import { IYtTask } from '@/interfaces/yt-tasks';

export const getters = {
  YtTasks: (state: YtTaskState) => state.yt_tasks,
  oneYtTask: (state: YtTaskState) => (taskId: number) => state.yt_tasks.find((task: IYtTask) => task.id === taskId),
};

const { read } = getStoreAccessors<YtTaskState, State>('');

export const readOneYtTask = read(getters.oneYtTask);
export const readYtTasks = read(getters.YtTasks);
