import { mutations } from './mutations';
import { getters } from './getters';
import { actions } from './actions';
import { SiteParserTemplateState } from './state';

const defaultState: SiteParserTemplateState = {
  templates: [],
};

export const siteParserTemplateModule = {
  state: defaultState,
  mutations,
  actions,
  getters,
};
