import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';

import { mainModule } from './main';
import { State } from './state';
import { adminModule } from './admin';
// import { telegramChannelsModule } from './telegram-channels';
// import { telegramChannelsTaskModule } from './telegram-channels-tasks';
import { seTaskModule } from './se-tasks';
import { ytTaskModule } from './yt-tasks';
import { tgTaskModule } from './tg-tasks';
import { avitoTaskModule } from './avito-tasks';
// import { youtubeModule } from './youtube';
import { googleSearchModule } from './google-search';
import { telegramModule } from './telegram';
import { YoutubeStatModule } from './youtube-stat';
import { browserModule } from './file-browser';
import { plugsCheckerTaskModule } from './plugs-checker-tasks';
import { ClaimModule } from './claim';
import { matrixModule } from './matrix';
import { rknCheckerModule } from './rkn-checker';
import { siteParserTaskModule } from './site-parser';
import { siteParserTemplateModule } from './site-parser-template';
import { siteTrafficTaskModule } from './site-traffic';
import { wordstatTaskModule } from './wordstat';
import { registrarTaskModule } from './registrar';

Vue.use(Vuex);

const storeOptions: StoreOptions<State> = {
  modules: {
    main: mainModule,
    admin: adminModule,
    // telegramChannels: telegramChannelsModule,
    // telegramChannelsTasks: telegramChannelsTaskModule,
    seTasks: seTaskModule,
    ytTasks: ytTaskModule,
    // youtube: youtubeModule,
    googleSearch: googleSearchModule,
    telegram: telegramModule,
    youtubeStat: YoutubeStatModule,
    browser: browserModule,
    plugsCheckerTask: plugsCheckerTaskModule,
    claim: ClaimModule,
    matrix: matrixModule,
    tgTasks: tgTaskModule,
    avitoTasks: avitoTaskModule,
    rknChecker: rknCheckerModule,
    siteParserTask: siteParserTaskModule,
    siteParserTemplate: siteParserTemplateModule,
    siteTrafficTask: siteTrafficTaskModule,
    wordstatTask: wordstatTaskModule,
    rgistrarTask: registrarTaskModule,
  },
};

export const store = new Vuex.Store<State>(storeOptions);

export default store;
