export interface IClaimState {
    status: number | null;
    total: number | null;
    done: number | null;
    message?: string;
}

export const claimInitialState: IClaimState = {
    status: null,
    total: null,
    done: null,
};
