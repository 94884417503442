import axios from 'axios';
import { getStoreAccessors } from 'typesafe-vuex';
import { ActionContext } from 'vuex';

import { api } from '@/api';
import { YoutubeStatState } from './state';
import {
  commitSetYoutubeStatFinished,
  commitSetYoutubeStatState,
} from './mutations';
import { dispatchCheckApiError } from '../main/actions';
import { State } from '../state';

type MainContext = ActionContext<YoutubeStatState, State>;

export const actions = {
  async actionGetYoutubeStatStatus(context: MainContext) {
    try {
      const { data } = await api.getYoutubeStatStatus(context.rootState.main.token);
      commitSetYoutubeStatState(context, data);
      return data;
    } catch (error) {
      if (axios.isAxiosError(error)) await dispatchCheckApiError(context, error);
    }
  },
  async actionRunYoutubeStat(context: MainContext) {
    try {
      const { data } = await api.runYoutubeStat(context.rootState.main.token);
      return data;
    } catch (error) {
      if (axios.isAxiosError(error)) await dispatchCheckApiError(context, error);
    }
  },
  async actionCancelYoutubeStat(context: MainContext) {
    try {
      await api.cancelYoutubeStat(context.rootState.main.token);
      commitSetYoutubeStatFinished(context, true);
      return true;
    } catch (error) {
      if (axios.isAxiosError(error)) await dispatchCheckApiError(context, error);
    }
  },
};

const { dispatch } = getStoreAccessors<YoutubeStatState, State>('');

export const dispatchGetYoutubeStatStatus = dispatch(actions.actionGetYoutubeStatStatus);
export const dispatchRunYoutubeStat = dispatch(actions.actionRunYoutubeStat);
export const dispatchCancelYoutubeStat = dispatch(actions.actionCancelYoutubeStat);
