import { ClaimState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';
import { IClaimState } from '@/interfaces/claim';


export const mutations = {
  setClaimFinished(state: ClaimState, payload: boolean) {
    state.finished = payload;
  },
  setClaimState(state: ClaimState, payload: IClaimState) {
    state.state = payload;
  },
};

const { commit } = getStoreAccessors<ClaimState, State>('');

export const commitSetClaimFinished = commit(mutations.setClaimFinished);
export const commitSetClaimState = commit(mutations.setClaimState);
