import { SeTaskState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const getters = {
    seTasks: (state: SeTaskState) => state.se_tasks,
    oneSeTask: (state: SeTaskState) => (taskId: number) => {
        const filteredTasks = state.se_tasks.filter((task) => task.id === taskId);
        if (filteredTasks.length > 0) return { ...filteredTasks[0] };
    },
};

const { read } = getStoreAccessors<SeTaskState, State>('');

export const readOneSeTask = read(getters.oneSeTask);
export const readSeTasks = read(getters.seTasks);
