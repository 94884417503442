import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';
import { BrowserState, IFolder } from './state';

export const getters = {
  browserFiles: (state: BrowserState) => (workDir: string) => {
    const folder = state.folders.find((fd: IFolder) => (fd.name === workDir));
    if (folder) return folder.files;
    return [];
  },
};

const { read } = getStoreAccessors<BrowserState, State>('');

export const readBrowserFiles = read(getters.browserFiles);
