import { getStoreAccessors } from 'typesafe-vuex';

import { TgTaskState } from './state';
import { State } from '../state';
import { ITgTask } from '@/interfaces/tg-tasks';

export const getters = {
  TgTasks: (state: TgTaskState) => state.tg_tasks,
  oneTgTask: (state: TgTaskState) => (taskId: number) => state.tg_tasks.find((task: ITgTask) => task.id === taskId),
};

const { read } = getStoreAccessors<TgTaskState, State>('');

export const readOneTgTask = read(getters.oneTgTask);
export const readTgTasks = read(getters.TgTasks);
