import { mutations } from './mutations';
import { getters } from './getters';
import { actions } from './actions';
import { YoutubeStatState } from './state';
import { youtubeStatInitialState } from '@/interfaces/youtube-stat';

const defaultState: YoutubeStatState = {
  finished: false,
  state: youtubeStatInitialState,
};

export const YoutubeStatModule = {
  state: defaultState,
  mutations,
  actions,
  getters,
};
