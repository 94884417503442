import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';
import { GoogleSearchScreenshotsState } from './state';

export const getters = {
    googleSearchFinished: (state: GoogleSearchScreenshotsState) => state.finished,
    googleSearchState: (state: GoogleSearchScreenshotsState) => state.state,
};

const { read } = getStoreAccessors<GoogleSearchScreenshotsState, State>('');

export const readGoogleSearchFinished = read(getters.googleSearchFinished);
export const readGoogleSearchState = read(getters.googleSearchState);
