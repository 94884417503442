import { AxiosError } from 'axios';
import { getStoreAccessors } from 'typesafe-vuex';
import { ActionContext } from 'vuex';

import { api } from '@/api';
import { API_DOMAIN } from '@/constants';
import { apiUrl } from '@/env';
import { ISiteTrafficTaskCreate, ISiteTrafficTaskUpdate } from '@/interfaces/site-traffic';
import { delay } from '@/utils';
import { dispatchCheckApiError } from '../main/actions';
import { commitAddNotification, commitRemoveNotification } from '../main/mutations';
import { State } from '../state';
import { readOneSiteTrafficTask } from './getters';
import {
  commitSetSiteTrafficTask, commitSetSiteTrafficTaskIsDownloading, commitSetSiteTrafficTasks,
  commitSetSiteTrafficTasksStat, commitSetSiteTrafficTaskStatus,
} from './mutations';
import { SiteTrafficTaskState } from './state';
import { authHeaders, downloadFile, withDefaultValue } from '../store-utils';

type MainContext = ActionContext<SiteTrafficTaskState, State>;

const MAX_FAILED_ATTEMPTS = 2 * 60; // 60 minutes
const ATTEMPTS_INTERVAL = 30 * 1000;  // 30 seconds
const API_URL = apiUrl + API_DOMAIN;
const DOWNLOAD_ONCE = true;

const utils = {
  counter: withDefaultValue({}),  // to control the number of failed attempts

  async downloadSiteTrafficTaskResult(context: MainContext, payload: { id: number, result: string }, once = false) {
    const url = `${API_URL}/site-traffic/${payload.id}/result`;
    const { token } = context.rootState.main;
    const { id, result } = payload;

    const updateStatuses = () => {
      commitSetSiteTrafficTaskIsDownloading(context, { id, isDownloading: false});
      commitSetSiteTrafficTaskStatus(context, { id, status: 'ENDED' });
    };

    if (once) {
      commitSetSiteTrafficTaskIsDownloading(context, { id, isDownloading: true });
      commitSetSiteTrafficTaskStatus(context, { id, status: 'DOWNLOAD' });
    } else {
      const updatedTask = readOneSiteTrafficTask(context)(id);
      if (updatedTask?.status !== 'EXPORT' && !updatedTask?.result?.endsWith('.zip')) {
        delete this.counter[id];
        return;
      }
    }

    // на случай, если захотим брать название файла отчта из store
    // const result = context.state.se_tasks.find((YtTask => YtTask.id === payload.id))?.result;
    // console.log('task.result --> ', result);

    try {
      const response = await fetch(url, authHeaders(token));
      if (response.status !== 200) throw new Error(`Response status: ${response.status}`);
      const bytes = await response.blob();
      delete this.counter[id];
      downloadFile(bytes, result);
      commitAddNotification(context, { content: 'Файл отчета успешно загружен', color: 'success' });
      updateStatuses();
    } catch (error) {
      if (once) {
        // console.log('Ошибка загрузки файла:', error);
        commitAddNotification(context, {
          content: 'Ошибка загрузки файла',
          color: 'error',
        });
        updateStatuses();
      } else if (this.counter[id] < MAX_FAILED_ATTEMPTS) {
          setTimeout(() => this.downloadSiteTrafficTaskResult(context, payload), ATTEMPTS_INTERVAL);
          this.counter[id]++;
        } else {
          delete this.counter[id];
          commitAddNotification(context, {
            content: 'Превышено максимальное количество попыток скачивания файла. Операция отменена',
            color: 'error' });
          updateStatuses();
        }
    }
  },
};


export const actions = {
  async actionGetSiteTrafficTasks(context: MainContext) {
    try {
      const { data } = await api.getSiteTrafficTasks(context.rootState.main.token);
      commitSetSiteTrafficTasks(context, data);
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },

  async actionUpdateSiteTrafficTask(context: MainContext, payload: { id: number, task: ISiteTrafficTaskUpdate }) {
    const { token } = context.rootState.main;
    const { id, task } = payload;
    try {
      const loadingNotification = { content: 'Сохранение', showProgress: true };
      commitAddNotification(context, loadingNotification);
      const [{ data }] = await Promise.all([api.updateSiteTrafficTask(token, id, task), delay(500)]);
      commitSetSiteTrafficTask(context, data);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, { content: 'Задача успешно обновлена', color: 'success' });
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },

  async actionCreateSiteTrafficTask(context: MainContext, payload: ISiteTrafficTaskCreate) {
    const { token } = context.rootState.main;
    try {
      const loadingNotification = { content: 'Создание', showProgress: true };
      commitAddNotification(context, loadingNotification);
      const [{ data }] = await Promise.all([api.createSiteTrafficTask(token, payload), delay(500)]);
      commitSetSiteTrafficTask(context, data);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, { content: 'Задача успешно создана', color: 'success' });
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },

  async actionDeleteSiteTrafficTask(context: MainContext, payload: { id: number }) {
    const { token } = context.rootState.main;
    try {
      const loadingNotification = { content: 'Удаление', showProgress: true };
      commitAddNotification(context, loadingNotification);
      const [{ data }] = await Promise.all([api.deleteSiteTrafficTask(token, payload.id), delay(500)]);
      commitSetSiteTrafficTask(context, data);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, { content: 'Задача успешно удалена', color: 'success' });
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },

  async actionRunSiteTrafficTask(context: MainContext, payload: { id: number }) {
    const { token } = context.rootState.main;
    const { id } = payload;
    try {
      const loadingNotification = { content: 'Запуск', showProgress: true };
      commitAddNotification(context, loadingNotification);
      await Promise.all([api.runSiteTrafficTask(token, id), delay(500)]);
      commitSetSiteTrafficTaskStatus(context, { id, status: 'ACTIVE' });
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, { content: 'Задача успешно запущена', color: 'success' });
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },

  async actionKillSiteTrafficTask(context: MainContext, payload: { id: number }) {
    const { token } = context.rootState.main;
    try {
      const loadingNotification = { content: 'Прерывание', showProgress: true };
      commitAddNotification(context, loadingNotification);
      const [{ data }] = await Promise.all([api.killSiteTrafficTask(token, payload.id), delay(500)]);
      commitSetSiteTrafficTask(context, data);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, { content: 'Задача успешно прервана', color: 'success' });
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },

  async actionStatSiteTrafficTasks(context: MainContext) {
    const { token } = context.rootState.main;
    try {
      const { data } = await api.statSiteTrafficTasks(token);
      commitSetSiteTrafficTasksStat(context, data);
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },

  // загружаем файл отчета
  async actionDownloadSiteTrafficTaskResult(context: MainContext, payload: { id: number, result: string }) {
    utils.downloadSiteTrafficTaskResult(context, payload, DOWNLOAD_ONCE);
  },

  async actionDownloadSiteTrafficTaskFile(context: MainContext, payload: { taskFile: string }) {
    const { taskFile } = payload;
    const url = `${API_URL}/site-traffic/task-file/${taskFile}`;
    const { token } = context.rootState.main;

    try {
      const response = await fetch(url, authHeaders(token));
      if (response.status !== 200) throw new Error(`Response status: ${response.status}`);
      const bytes = await response.blob();
      downloadFile(bytes, taskFile);
      commitAddNotification(context, { content: 'Файл отчета успешно загружен', color: 'success' });
    } catch (error) {
        commitAddNotification(context, {
          content: 'Ошибка загрузки файла',
          color: 'error',
        });
    }
  },

};

const { dispatch } = getStoreAccessors<SiteTrafficTaskState, State>('');

export const dispatchCreateSiteTrafficTask = dispatch(actions.actionCreateSiteTrafficTask);
export const dispatchGetSiteTrafficTasks = dispatch(actions.actionGetSiteTrafficTasks);
export const dispatchUpdateSiteTrafficTask = dispatch(actions.actionUpdateSiteTrafficTask);
export const dispatchDeleteSiteTrafficTask = dispatch(actions.actionDeleteSiteTrafficTask);
export const dispatchRunSiteTrafficTask = dispatch(actions.actionRunSiteTrafficTask);
export const dispatchKillSiteTrafficTask = dispatch(actions.actionKillSiteTrafficTask);
export const dispatchStatSiteTrafficTasks = dispatch(actions.actionStatSiteTrafficTasks);
export const dispatchDownloadSiteTrafficTaskResult = dispatch(actions.actionDownloadSiteTrafficTaskResult);
export const dispatchDownloadSiteTrafficTaskFile = dispatch(actions.actionDownloadSiteTrafficTaskFile);
