import { getStoreAccessors } from 'typesafe-vuex';

import { ITelegramState } from '@/interfaces/telegram';
import { State } from '../state';
import { TelegramState } from './state';

export const mutations = {
  setTelegramFinished(state: TelegramState, payload: boolean) {
    state.finished = payload;
  },
  setTelegramState(state: TelegramState, payload: ITelegramState) {
    state.state = payload;
  },
};

const { commit } = getStoreAccessors<TelegramState, State>('');

export const commitSetTelegramFinished = commit(mutations.setTelegramFinished);
export const commitSetTelegramState = commit(mutations.setTelegramState);
