import { mutations } from './mutations';
import { getters } from './getters';
import { actions } from './actions';
import { YtTaskState } from './state';

const defaultState: YtTaskState = {
  yt_tasks: [],
};

export const ytTaskModule = {
  state: defaultState,
  mutations,
  actions,
  getters,
};
