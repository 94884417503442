import { getStoreAccessors } from 'typesafe-vuex';

import { RegistrarTaskState } from './state';
import { State } from '../state';
import { IRegistrarTask } from '@/interfaces/registrar';

export const getters = {
  RegistrarTasks: (state: RegistrarTaskState) => state.tasks,
  oneRegistrarTask: (state: RegistrarTaskState) => (taskId: number) => state.tasks.find(
    (task: IRegistrarTask) => task.id === taskId),
};

const { read } = getStoreAccessors<RegistrarTaskState, State>('');

export const readOneRegistrarTask = read(getters.oneRegistrarTask);
export const readRegistrarTasks = read(getters.RegistrarTasks);
