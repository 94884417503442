import { AxiosError } from 'axios';
import { getStoreAccessors } from 'typesafe-vuex';
import { ActionContext } from 'vuex';

import { api } from '@/api';
import { dispatchCheckApiError } from '../main/actions';
import { commitSetMatrixFinished, commitSetMatrixState } from './mutations';
import { MatrixState } from './state';
import { State } from '../state';
import { IMatrixTaskCreate } from '@/interfaces/matrix';
import { delay } from '@/utils';
import { commitAddNotification } from '../main/mutations';

type MainContext = ActionContext<MatrixState, State>;

export const actions = {
  async actionGetMatrixStatus(context: MainContext) {
    try {
        const { data } = await api.getMatrixStatus(context.rootState.main.token);
        commitSetMatrixState(context, data);
        return data;
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },

  async actionRunMatrix(context: MainContext, payload: IMatrixTaskCreate) {
    const { token } = context.rootState.main;
    try {
      const [{ data }] = await Promise.all([api.runMatrix(token, payload), delay(500)]);
      return data;
    } catch (error) {
      let detail = (error as AxiosError).response?.data.detail || '';
      if (detail.includes('Running')) detail = 'Задача запущена на другом устройстве';
      commitAddNotification(context, { content: detail, color: 'error' });
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },

  async actionCancelMatrix(context: MainContext) {
    try {
      await api.cancelMatrix(context.rootState.main.token);
      commitSetMatrixFinished(context, true);
      return true;
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },
};

const { dispatch } = getStoreAccessors<MatrixState, State>('');

export const dispatchGetMatrixStatus = dispatch(actions.actionGetMatrixStatus);
export const dispatchRunMatrix = dispatch(actions.actionRunMatrix);
export const dispatchCancelMatrix = dispatch(actions.actionCancelMatrix);
