import { AxiosError } from 'axios';
import { getStoreAccessors } from 'typesafe-vuex';
import { ActionContext } from 'vuex';

import { api } from '@/api';
import { API_DOMAIN } from '@/constants';
import { apiUrl } from '@/env';
import { IRegistrarTaskCreate, IRegistrarTaskUpdate } from '@/interfaces/registrar';
import { delay } from '@/utils';
import { dispatchCheckApiError } from '../main/actions';
import { commitAddNotification, commitRemoveNotification } from '../main/mutations';
import { State } from '../state';
import { readOneRegistrarTask } from './getters';
import {
  commitSetRegistrarTask, commitSetRegistrarTaskIsDownloading, commitSetRegistrarTasks,
  commitSetRegistrarTasksStat, commitSetRegistrarTaskStatus,
} from './mutations';
import { RegistrarTaskState } from './state';
import { authHeaders, downloadFile, withDefaultValue } from '../store-utils';

type MainContext = ActionContext<RegistrarTaskState, State>;

const MAX_FAILED_ATTEMPTS = 2 * 60; // 60 minutes
const ATTEMPTS_INTERVAL = 30 * 1000;  // 30 seconds
const API_URL = apiUrl + API_DOMAIN;
const DOWNLOAD_ONCE = true;

const utils = {
  counter: withDefaultValue({}),  // to control the number of failed attempts

  async downloadRegistrarTaskResult(context: MainContext, payload: { id: number, result: string }, once = false) {
    const url = `${API_URL}/registrar/${payload.id}/result`;
    const { token } = context.rootState.main;
    const { id, result } = payload;

    const updateStatuses = () => {
      commitSetRegistrarTaskIsDownloading(context, { id, isDownloading: false});
      commitSetRegistrarTaskStatus(context, { id, status: 'ENDED' });
    };

    if (once) {
      commitSetRegistrarTaskIsDownloading(context, { id, isDownloading: true });
      commitSetRegistrarTaskStatus(context, { id, status: 'DOWNLOAD' });
    } else {
      const updatedTask = readOneRegistrarTask(context)(id);
      if (updatedTask?.status !== 'EXPORT' && !updatedTask?.result?.endsWith('.zip')) {
        delete this.counter[id];
        return;
      }
    }

    // на случай, если захотим брать название файла отчта из store
    // const result = context.state.se_tasks.find((YtTask => YtTask.id === payload.id))?.result;
    // console.log('task.result --> ', result);

    try {
      const response = await fetch(url, authHeaders(token));
      if (response.status !== 200) throw new Error(`Response status: ${response.status}`);
      const bytes = await response.blob();
      delete this.counter[id];
      downloadFile(bytes, result);
      commitAddNotification(context, { content: 'Файл отчета успешно загружен', color: 'success' });
      updateStatuses();
    } catch (error) {
      if (once) {
        // console.log('Ошибка загрузки файла:', error);
        commitAddNotification(context, {
          content: 'Ошибка загрузки файла',
          color: 'error',
        });
        updateStatuses();
      } else if (this.counter[id] < MAX_FAILED_ATTEMPTS) {
          setTimeout(() => this.downloadRegistrarTaskResult(context, payload), ATTEMPTS_INTERVAL);
          this.counter[id]++;
        } else {
          delete this.counter[id];
          commitAddNotification(context, {
            content: 'Превышено максимальное количество попыток скачивания файла. Операция отменена',
            color: 'error' });
          updateStatuses();
        }
    }
  },
};


export const actions = {
  async actionGetRegistrarTasks(context: MainContext) {
    try {
      const { data } = await api.getRegistrarTasks(context.rootState.main.token);
      commitSetRegistrarTasks(context, data);
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },

  async actionUpdateRegistrarTask(context: MainContext, payload: { id: number, task: IRegistrarTaskUpdate }) {
    const { token } = context.rootState.main;
    const { id, task } = payload;
    try {
      const loadingNotification = { content: 'Сохранение', showProgress: true };
      commitAddNotification(context, loadingNotification);
      const [{ data }] = await Promise.all([api.updateRegistrarTask(token, id, task), delay(500)]);
      commitSetRegistrarTask(context, data);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, { content: 'Задача успешно обновлена', color: 'success' });
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },

  async actionCreateRegistrarTask(context: MainContext, payload: IRegistrarTaskCreate) {
    const { token } = context.rootState.main;
    try {
      const loadingNotification = { content: 'Создание', showProgress: true };
      commitAddNotification(context, loadingNotification);
      const [{ data }] = await Promise.all([api.createRegistrarTask(token, payload), delay(500)]);
      commitSetRegistrarTask(context, data);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, { content: 'Задача успешно создана', color: 'success' });
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },

  async actionDeleteRegistrarTask(context: MainContext, payload: { id: number }) {
    const { token } = context.rootState.main;
    try {
      const loadingNotification = { content: 'Удаление', showProgress: true };
      commitAddNotification(context, loadingNotification);
      const [{ data }] = await Promise.all([api.deleteRegistrarTask(token, payload.id), delay(500)]);
      commitSetRegistrarTask(context, data);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, { content: 'Задача успешно удалена', color: 'success' });
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },

  async actionRunRegistrarTask(context: MainContext, payload: { id: number }) {
    const { token } = context.rootState.main;
    const { id } = payload;
    try {
      const loadingNotification = { content: 'Запуск', showProgress: true };
      commitAddNotification(context, loadingNotification);
      await Promise.all([api.runRegistrarTask(token, id), delay(500)]);
      commitSetRegistrarTaskStatus(context, { id, status: 'ACTIVE' });
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, { content: 'Задача успешно запущена', color: 'success' });
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },

  async actionKillRegistrarTask(context: MainContext, payload: { id: number }) {
    const { token } = context.rootState.main;
    try {
      const loadingNotification = { content: 'Прерывание', showProgress: true };
      commitAddNotification(context, loadingNotification);
      const [{ data }] = await Promise.all([api.killRegistrarTask(token, payload.id), delay(500)]);
      commitSetRegistrarTask(context, data);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, { content: 'Задача успешно прервана', color: 'success' });
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },

  async actionStatRegistrarTasks(context: MainContext) {
    const { token } = context.rootState.main;
    try {
      const { data } = await api.statRegistrarTasks(token);
      commitSetRegistrarTasksStat(context, data);
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },

  // загружаем файл отчета
  async actionDownloadRegistrarTaskResult(context: MainContext, payload: { id: number, result: string }) {
    utils.downloadRegistrarTaskResult(context, payload, DOWNLOAD_ONCE);
  },

  async actionDownloadRegistrarTaskFile(context: MainContext, payload: { taskFile: string }) {
    const { taskFile } = payload;
    const url = `${API_URL}/registrar/task-file/${taskFile}`;
    const { token } = context.rootState.main;

    try {
      const response = await fetch(url, authHeaders(token));
      if (response.status !== 200) throw new Error(`Response status: ${response.status}`);
      const bytes = await response.blob();
      downloadFile(bytes, taskFile);
      commitAddNotification(context, { content: 'Файл отчета успешно загружен', color: 'success' });
    } catch (error) {
        commitAddNotification(context, {
          content: 'Ошибка загрузки файла',
          color: 'error',
        });
    }
  },

};

const { dispatch } = getStoreAccessors<RegistrarTaskState, State>('');

export const dispatchCreateRegistrarTask = dispatch(actions.actionCreateRegistrarTask);
export const dispatchGetRegistrarTasks = dispatch(actions.actionGetRegistrarTasks);
export const dispatchUpdateRegistrarTask = dispatch(actions.actionUpdateRegistrarTask);
export const dispatchDeleteRegistrarTask = dispatch(actions.actionDeleteRegistrarTask);
export const dispatchRunRegistrarTask = dispatch(actions.actionRunRegistrarTask);
export const dispatchKillRegistrarTask = dispatch(actions.actionKillRegistrarTask);
export const dispatchStatRegistrarTasks = dispatch(actions.actionStatRegistrarTasks);
export const dispatchDownloadRegistrarTaskResult = dispatch(actions.actionDownloadRegistrarTaskResult);
export const dispatchDownloadRegistrarTaskFile = dispatch(actions.actionDownloadRegistrarTaskFile);
