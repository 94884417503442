import axios from 'axios';
import { apiUrl } from '@/env';
import {
  IUserProfile, IUserProfileUpdate, IUserProfileCreate,

} from './interfaces/users';
import {
  ITelegramChannel,
  ITelegramChannelCreate,
  ITelegramChannelUpdate,
} from '@/interfaces/telegram-channels';

import {
  ITelegramChannelTask,
  ITelegramChannelTaskCreate,
  ITelegramChannelTaskUpdate,
  ITelegramChannelTasksStatFilter,
} from '@/interfaces/telegram-channels-tasks';

import {
  ISeTask,
  ISeTaskCreate,
  ISeTaskUpdate,
  ISeTasksStatFilter,
} from '@/interfaces/se-tasks';

import {
  IYoutubeSeKeys,
} from '@/interfaces/youtube';
import { IGoogleSearchScreenshot } from './interfaces/google-search';
import { ITelegramReportFile } from './interfaces/telegram';
import { API_DOMAIN } from './constants';
import { IFile } from './interfaces/file-browser';
import { IYtTask, IYtTaskCreate, IYtTaskUpdate } from './interfaces/yt-tasks';
// import { IPlugsCheckerFile, IPlugsCheckerTaskCreate } from './interfaces/plugs-checker';
import { IPlugsCheckerTask, IPlugsCheckerTaskCreate, IPlugsCheckerTaskUpdate } from './interfaces/plugs-checker-tasks';
import { IMatrixFile, IMatrixTaskCreate } from './interfaces/matrix';
import { ITgTask, ITgTaskCreate, ITgTaskUpdate } from './interfaces/tg-tasks';
import { IAvitoTask, IAvitoTaskCreate, IAvitoTaskUpdate } from './interfaces/avito-tasks';
import { IRknCheckerFile, IRknCheckerTaskCreate } from './interfaces/rkn-checker';
import {
  ISiteParserTask,
  ISiteParserTaskCreate,
  ISiteParserTaskUpdate,
} from './interfaces/site-parser';
import { ISiteTrafficTask, ISiteTrafficTaskCreate, ISiteTrafficTaskUpdate } from './interfaces/site-traffic';
import { IWordstatTask, IWordstatTaskCreate, IWordstatTaskUpdate } from './interfaces/wordstat';
import { ISiteTemplate, ISiteTemplateCreate, ISiteTemplateUpdate } from './interfaces/site-parser-template';
import { IRegistrarTask, IRegistrarTaskCreate, IRegistrarTaskUpdate } from './interfaces/registrar';

const API_URL = apiUrl + API_DOMAIN;
const plugsCheckerBaseEndpoint = 'plugs-checker';
const siteParserBaseEndpoint = 'site-parser';
const siteParserConfigBaseEndpoint = 'site-parser-config';
const siteTrafficBaseEndpoint = 'site-traffic';
const wordstatEndpoint = 'wordstat';
const registrarEndpoint = 'registrar';

function authHeaders(token: string) {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

export const api = {
  async logInGetToken(username: string, password: string) {
    const params = new URLSearchParams();
    params.append('username', username);
    params.append('password', password);

    return axios.post(`${API_URL}/login/access-token`, params);
  },

  async getMe(token: string) {
    return axios.get<IUserProfile>(`${API_URL}/users/me`, authHeaders(token));
  },
  async updateMe(token: string, data: IUserProfileUpdate) {
    return axios.put<IUserProfile>(`${API_URL}/users/me`, data, authHeaders(token));
  },
  async passwordRecovery(email: string) {
    return axios.post(`${API_URL}/password-recovery/${email}`);
  },
  async resetPassword(password: string, token: string) {
    return axios.post(`${API_URL}/reset-password/`, {
      new_password: password,
      token,
    });
  },

  async getUsers(token: string) {
    return axios.get<IUserProfile[]>(`${API_URL}/users/`, authHeaders(token));
  },
  async updateUser(token: string, userId: number, data: IUserProfileUpdate) {
    return axios.put(`${API_URL}/users/${userId}`, data, authHeaders(token));
  },
  async createUser(token: string, data: IUserProfileCreate) {
    return axios.post(`${API_URL}/users/`, data, authHeaders(token));
  },

  async getTelegramChannels(token: string) {
    return axios.get<ITelegramChannel[]>(`${API_URL}/telegram-channels/`, authHeaders(token));
  },
  async updateTelegramChannel(token: string, channelId: number, data: ITelegramChannelUpdate) {
    return axios.put(`${API_URL}/telegram-channels/${channelId}`, data, authHeaders(token));
  },
  async createTelegramChannel(token: string, data: ITelegramChannelCreate) {
    return axios.post(`${API_URL}/telegram-channels/`, data, authHeaders(token));
  },

  async getTelegramChannelsTasks(token: string) {
    return axios.get<ITelegramChannelTask[]>(`${API_URL}/telegram-channels-tasks/`, authHeaders(token));
  },
  async updateTelegramChannelTask(token: string, taskId: number, data: ITelegramChannelTaskUpdate) {
    return axios.put(`${API_URL}/telegram-channels-tasks/${taskId}`, data, authHeaders(token));
  },
  async createTelegramChannelTask(token: string, data: ITelegramChannelTaskCreate) {
    return axios.post(`${API_URL}/telegram-channels-tasks/`, data, authHeaders(token));
  },
  async deleteTelegramChannelTask(token: string, taskId: number) {
    return axios.delete(`${API_URL}/telegram-channels-tasks/${taskId}`, authHeaders(token));
  },
  async runTelegramChannelTask(token: string, taskId: number) {
    return axios.post(`${API_URL}/telegram-channels-tasks/process/${taskId}`, {}, authHeaders(token));
  },
  async killTelegramChannelTask(token: string, taskId: number) {
    return axios.delete(`${API_URL}/telegram-channels-tasks/process/${taskId}`, authHeaders(token));
  },
  async statTelegramChannelTasks(token: string, filter: ITelegramChannelTasksStatFilter) {
    return axios.post(`${API_URL}/telegram-channels-tasks/process-stat`, filter, authHeaders(token));
  },

  // SeTasks block ----------------------------------------------------------------
  async getSeTasks(token: string) {
    return axios.get<ISeTask[]>(`${API_URL}/se-tasks/`, authHeaders(token));
  },
  async updateSeTask(token: string, taskId: number, data: ISeTaskUpdate) {
    return axios.put(`${API_URL}/se-tasks/${taskId}`, data, authHeaders(token));
  },
  async createSeTask(token: string, data: ISeTaskCreate) {
    const params = authHeaders(token);
    const paramsKey = 'params';
    params[paramsKey] = {name: data.name, deep: data.deep, description: data.description};

    const formData = new FormData();
    Object.keys(data).forEach((key) => formData.append(key, data[key]));
    return axios.post(`${API_URL}/se-tasks/`, formData, params);
  },
  async deleteSeTask(token: string, taskId: number) {
    return axios.delete(`${API_URL}/se-tasks/${taskId}`, authHeaders(token));
  },
  async runSeTask(token: string, taskId: number) {
    return axios.post(`${API_URL}/se-tasks/process/${taskId}`, {}, authHeaders(token));
  },
  async killSeTask(token: string, taskId: number) {
    return axios.delete(`${API_URL}/se-tasks/process/${taskId}`, authHeaders(token));
  },
  async statSeTasks(token: string, filter: ISeTasksStatFilter) {
    return axios.post(`${API_URL}/se-tasks/process-stat`, filter, authHeaders(token));
  },
  async exportSeTask(token: string, id: number, dateFrom: string, dateTo: string) {
    // DEBUG
    // console.log(`${API_URL}/se-tasks/${id}/export`);
    const { headers } = authHeaders(token);
    return axios.get(
      `${API_URL}/se-tasks/${id}/export`,
      {
        params: {
          date_start: dateFrom,
          date_end: dateTo,
        },
        headers,
      },
      );
  },
  async downloadResultSeTask(token: string, id: number) {
    // DEBUG
    // console.log('downloadResultSeTask');
    // console.log(`${API_URL}/se-tasks/${id}/result`);
    return axios.get(`${API_URL}/se-tasks/${id}/result`, authHeaders(token));
  },
  async killExportSeTask(token: string, taskId: number) {
    return axios.delete(`${API_URL}/se-tasks/${taskId}/export`, authHeaders(token));
  },

  // YtTasks block ----------------------------------------------------------------
  async getYtTasks(token: string) {
    return axios.get<IYtTask[]>(`${API_URL}/yt-tasks/`, authHeaders(token));
  },
  async updateYtTask(token: string, taskId: number, data: IYtTaskUpdate) {
    return axios.put(`${API_URL}/yt-tasks/${taskId}`, data, authHeaders(token));
  },
  async createYtTask(token: string, data: IYtTaskCreate) {
    const params = authHeaders(token);
    const paramsKey = 'params';
    params[paramsKey] = {name: data.name, deep: data.deep, description: data.description};

    const formData = new FormData();
    Object.keys(data).forEach((key) => formData.append(key, data[key]));
    return axios.post(`${API_URL}/yt-tasks/`, formData, params);
  },
  async deleteYtTask(token: string, taskId: number) {
    return axios.delete(`${API_URL}/yt-tasks/${taskId}`, authHeaders(token));
  },
  async runYtTask(token: string, taskId: number) {
    return axios.post(`${API_URL}/yt-tasks/process/${taskId}`, {}, authHeaders(token));
  },
  async killYtTask(token: string, taskId: number) {
    return axios.delete(`${API_URL}/yt-tasks/process/${taskId}`, authHeaders(token));
  },
  async statYtTasks(token: string) {
    return axios.post(`${API_URL}/yt-tasks/process-stat`, {}, authHeaders(token));
  },
  async downloadResultYtTask(token: string, id: number) {
    return axios.get(`${API_URL}/yt-tasks/${id}/result`, authHeaders(token));
  },
  async downloadYtTaskFile(token: string, taskFile: string) {
    return axios.get(`${API_URL}/yt-tasks/task-file/${taskFile}`, authHeaders(token));
  },

  // GoogleSearch block ----------------------------------------------------------------
  async getGoogleSearchScrenshots(token: string) {
    return axios.get<IGoogleSearchScreenshot[]>(`${API_URL}/google-search/result/list`, authHeaders(token));
  },
  async getGoogleSearchScrenshotFile(token: string, filename: string) {
    return axios.get(`${API_URL}/google-search/result/${filename}`, authHeaders(token));
  },
  async getGoogleSearchStatus(token: string) {
    return axios.get(`${API_URL}/google-search/status`, authHeaders(token));
  },
  async runGoogleSearch(token: string) {
    const data = {};
    return axios.post(`${API_URL}/google-search/run`, data, authHeaders(token));
  },
  async cancelGoogleSearch(token: string) {
    return axios.delete(`${API_URL}/google-search/cancel`, authHeaders(token));
  },
  async delGoogleSearchScreenshot(token: string, filename: string) {
    return axios.delete(`${API_URL}/google-search/result/${filename}`, authHeaders(token));
  },
  async delGoogleSearchAllScreenshots(token: string) {
    return axios.delete(`${API_URL}/google-search/result/list`, authHeaders(token));
  },

  // Telegram block ----------------------------------------------------------------
  async getTelegramReportFiles(token: string) {
    return axios.get<ITelegramReportFile[]>(`${API_URL}/telegram/result/list`, authHeaders(token));
  },
  async getTelegramReportFile(token: string, filename: string) {
    return axios.get(`${API_URL}/telegram/result/${filename}`, authHeaders(token));
  },
  async getTelegramStatus(token: string) {
    return axios.get(`${API_URL}/telegram/status`, authHeaders(token));
  },
  async runTelegram(token: string) {
    return axios.post(`${API_URL}/telegram/run`, {}, authHeaders(token));
  },
  async cancelTelegram(token: string) {
    return axios.delete(`${API_URL}/telegram/cancel`, authHeaders(token));
  },
  async delTelegramReportFile(token: string, filename: string) {
    return axios.delete(`${API_URL}/telegram/result/${filename}`, authHeaders(token));
  },
  async delTelegramAllReportFiles(token: string) {
    return axios.delete(`${API_URL}/telegram/result/list`, authHeaders(token));
  },

  // FileBrowser block ----------------------------------------------------------------
  async getBrowserFiles(token: string, workDir: string) {
    return axios.get<IFile[]>(`${API_URL}/${workDir}/result/list`, authHeaders(token));
  },
  async getBrowserFile(token: string, workDir: string, filename: string) {
    return axios.get(`${API_URL}/${workDir}/result/${filename}`, authHeaders(token));
  },
  async delBrowserFile(token: string, workDir: string, filename: string) {
    return axios.delete(`${API_URL}/${workDir}/result/${filename}`, authHeaders(token));
  },
  async delBrowserAllFiles(token: string, workDir: string) {
    return axios.delete(`${API_URL}/${workDir}/result/list`, authHeaders(token));
  },


  // YoutubeStat block ----------------------------------------------------------------
  async getYoutubeStatReportFiles(token: string) {
    return axios.get<ITelegramReportFile[]>(`${API_URL}/youtube-stat/result/list`, authHeaders(token));
  },
  async getYoutubeStatReportFile(token: string, filename: string) {
    return axios.get(`${API_URL}/youtube-stat/result/${filename}`, authHeaders(token));
  },
  async getYoutubeStatStatus(token: string) {
    return axios.get(`${API_URL}/youtube-stat/status`, authHeaders(token));
  },
  async runYoutubeStat(token: string) {
    return axios.post(`${API_URL}/youtube-stat/run`, {}, authHeaders(token));
  },
  async cancelYoutubeStat(token: string) {
    return axios.delete(`${API_URL}/youtube-stat/cancel`, authHeaders(token));
  },
  async delYoutubeStatReportFile(token: string, filename: string) {
    return axios.delete(`${API_URL}/youtube-stat/result/${filename}`, authHeaders(token));
  },
  async delYoutubeStatAllReportFiles(token: string) {
    return axios.delete(`${API_URL}/youtube-stat/result/list`, authHeaders(token));
  },

  // YouTube -------------------------------
  async getYoutubeDataset(token: string) {
    return axios.get(`${API_URL}/youtube/get-dataset`, authHeaders(token));
  },
  async getYoutubeKeys(token: string) {
    return axios.get(`${API_URL}/youtube/keys`, authHeaders(token));
  },
  async updateYoutubeKeys(token: string, data: IYoutubeSeKeys) {
    return axios.post(`${API_URL}/youtube/keys`, data, authHeaders(token));
  },
  async getYoutubeMatchStat(token: string) {
    return axios.get(`${API_URL}/youtube/match-stat`, authHeaders(token));
  },
  async getYoutubeSeQueue(token: string) {
    return axios.get(`${API_URL}/youtube/se-queue`, authHeaders(token));
  },
  async getYoutubeMatchQueue(token: string) {
    return axios.get(`${API_URL}/youtube/match-queue`, authHeaders(token));
  },
  async restartYoutubeSe(token: string) {
    return axios.get(`${API_URL}/youtube/se-restart`, authHeaders(token));
  },
  async stopYoutubeSe(token: string) {
    return axios.get(`${API_URL}/youtube/se-stop`, authHeaders(token));
  },

  // // PlugsChecker block ----------------------------------------------------------------
  // async runPlugsChecker(token: string, data: IPlugsCheckerTaskCreate) {
  //   const params = authHeaders(token);
  //   const formData = new FormData();
  //   Object.keys(data).forEach((key) => formData.append(key, data[key]));
  //   return axios.post(`${API_URL}/plugs-checker/run`, formData, params);
  // },
  // async getPlugsCheckerFiles(token: string) {
  //   return axios.get<IPlugsCheckerFile[]>(`${API_URL}/plugs-checker/result/list`, authHeaders(token));
  // },
  // async getPlugsCheckerFile(token: string, filename: string) {
  //   return axios.get(`${API_URL}/plugs-checker/result/${filename}`, authHeaders(token));
  // },
  // async getPlugsCheckerStatus(token: string) {
  //   return axios.get(`${API_URL}/plugs-checker/status`, authHeaders(token));
  // },
  // async cancelPlugsChecker(token: string) {
  //   return axios.delete(`${API_URL}/plugs-checker/cancel`, authHeaders(token));
  // },
  // async delPlugsCheckerFile(token: string, filename: string) {
  //   return axios.delete(`${API_URL}/plugs-checker/result/${filename}`, authHeaders(token));
  // },
  // async delPlugsCheckerFiles(token: string) {
  //   return axios.delete(`${API_URL}/plugs-checker/result/list`, authHeaders(token));
  // },

  // PlugsCheckerTasks block ----------------------------------------------------------------
  async getPlugsCheckerTasks(token: string) {
    return axios.get<IPlugsCheckerTask[]>(`${API_URL}/${plugsCheckerBaseEndpoint}/`, authHeaders(token));
  },
  async updatePlugsCheckerTask(token: string, taskId: number, data: IAvitoTaskUpdate) {
    return axios.put(`${API_URL}/${plugsCheckerBaseEndpoint}/${taskId}`, data, authHeaders(token));
  },
  async createPlugsCheckerTask(token: string, data: IPlugsCheckerTaskCreate) {
    const params = authHeaders(token);
    const paramsKey = 'params';
    params[paramsKey] = {name: data.name, description: data.description};

    const formData = new FormData();
    Object.keys(data).forEach((key) => formData.append(key, data[key]));
    return axios.post(`${API_URL}/${plugsCheckerBaseEndpoint}/`, formData, params);
  },
  async deletePlugsCheckerTask(token: string, taskId: number) {
    return axios.delete(`${API_URL}/${plugsCheckerBaseEndpoint}/${taskId}`, authHeaders(token));
  },
  async runPlugsCheckerTask(token: string, taskId: number) {
    return axios.post(`${API_URL}/${plugsCheckerBaseEndpoint}/process/${taskId}`, {}, authHeaders(token));
  },
  async killPlugsCheckerTask(token: string, taskId: number) {
    return axios.delete(`${API_URL}/${plugsCheckerBaseEndpoint}/process/${taskId}`, authHeaders(token));
  },
  async statPlugsCheckerTasks(token: string) {
    return axios.post(`${API_URL}/${plugsCheckerBaseEndpoint}/process-stat`, {}, authHeaders(token));
  },
  async downloadResultPlugsCheckerTask(token: string, id: number) {
    return axios.get(`${API_URL}/${plugsCheckerBaseEndpoint}/${id}/result`, authHeaders(token));
  },
  async downloadPlugsCheckerTaskFile(token: string, taskFile: string) {
    return axios.get(`${API_URL}/${plugsCheckerBaseEndpoint}/task-file/${taskFile}`, authHeaders(token));
  },

  // Claim block ----------------------------------------------------------------
  async getClaimStatus(token: string) {
    return axios.get(`${API_URL}/claims/status`, authHeaders(token));
  },
  async runClaim(token: string) {
    const data = {};
    return axios.post(`${API_URL}/claims/run`, data, authHeaders(token));
  },
  async cancelClaim(token: string) {
    return axios.delete(`${API_URL}/claims/cancel`, authHeaders(token));
  },

  // Matrix block ----------------------------------------------------------------
  async runMatrix(token: string, data: IMatrixTaskCreate) {
    const params = authHeaders(token);
    const formData = new FormData();
    for (const file of data.files) formData.append('files', file);
    return axios.post(`${API_URL}/matrix/run`, formData, params);
  },
  async getMatrixFiles(token: string) {
    return axios.get<IMatrixFile[]>(`${API_URL}/matrix/result/list`, authHeaders(token));
  },
  async getMatrixFile(token: string, filename: string) {
    return axios.get(`${API_URL}/matrix/result/${filename}`, authHeaders(token));
  },
  async getMatrixStatus(token: string) {
    return axios.get(`${API_URL}/matrix/status`, authHeaders(token));
  },
  async cancelMatrix(token: string) {
    return axios.delete(`${API_URL}/matrix/cancel`, authHeaders(token));
  },
  async delMatrixFile(token: string, filename: string) {
    return axios.delete(`${API_URL}/matrix/result/${filename}`, authHeaders(token));
  },
  async delMatrixFiles(token: string) {
    return axios.delete(`${API_URL}/matrix/result/list`, authHeaders(token));
  },

  // TgTasks block ----------------------------------------------------------------
  async getTgTasks(token: string) {
    return axios.get<ITgTask[]>(`${API_URL}/tg-tasks/`, authHeaders(token));
  },
  async updateTgTask(token: string, taskId: number, data: ITgTaskUpdate) {
    return axios.put(`${API_URL}/tg-tasks/${taskId}`, data, authHeaders(token));
  },
  async createTgTask(token: string, data: ITgTaskCreate) {
    const params = authHeaders(token);
    const paramsKey = 'params';
    params[paramsKey] = {name: data.name, deep: data.deep, description: data.description};

    const formData = new FormData();
    Object.keys(data).forEach((key) => formData.append(key, data[key]));
    return axios.post(`${API_URL}/tg-tasks/`, formData, params);
  },
  async deleteTgTask(token: string, taskId: number) {
    return axios.delete(`${API_URL}/tg-tasks/${taskId}`, authHeaders(token));
  },
  async runTgTask(token: string, taskId: number) {
    return axios.post(`${API_URL}/tg-tasks/process/${taskId}`, {}, authHeaders(token));
  },
  async killTgTask(token: string, taskId: number) {
    return axios.delete(`${API_URL}/tg-tasks/process/${taskId}`, authHeaders(token));
  },
  async statTgTasks(token: string) {
    return axios.post(`${API_URL}/tg-tasks/process-stat`, {}, authHeaders(token));
  },
  async downloadResultTgTask(token: string, id: number) {
    return axios.get(`${API_URL}/tg-tasks/${id}/result`, authHeaders(token));
  },
  async downloadTgTaskFile(token: string, taskFile: string) {
    return axios.get(`${API_URL}/tg-tasks/task-file/${taskFile}`, authHeaders(token));
  },

  // AvitoTasks block ----------------------------------------------------------------
  async getAvitoTasks(token: string) {
    return axios.get<IAvitoTask[]>(`${API_URL}/avito-tasks/`, authHeaders(token));
  },
  async updateAvitoTask(token: string, taskId: number, data: IAvitoTaskUpdate) {
    return axios.put(`${API_URL}/avito-tasks/${taskId}`, data, authHeaders(token));
  },
  async createAvitoTask(token: string, data: IAvitoTaskCreate) {
    const params = authHeaders(token);
    const paramsKey = 'params';
    params[paramsKey] = {name: data.name, deep: data.deep, description: data.description};

    const formData = new FormData();
    Object.keys(data).forEach((key) => formData.append(key, data[key]));
    return axios.post(`${API_URL}/avito-tasks/`, formData, params);
  },
  async deleteAvitoTask(token: string, taskId: number) {
    return axios.delete(`${API_URL}/avito-tasks/${taskId}`, authHeaders(token));
  },
  async runAvitoTask(token: string, taskId: number) {
    return axios.post(`${API_URL}/avito-tasks/process/${taskId}`, {}, authHeaders(token));
  },
  async killAvitoTask(token: string, taskId: number) {
    return axios.delete(`${API_URL}/avito-tasks/process/${taskId}`, authHeaders(token));
  },
  async statAvitoTasks(token: string) {
    return axios.post(`${API_URL}/avito-tasks/process-stat`, {}, authHeaders(token));
  },
  async downloadResultAvitoTask(token: string, id: number) {
    return axios.get(`${API_URL}/avito-tasks/${id}/result`, authHeaders(token));
  },
  async downloadAvitoTaskFile(token: string, taskFile: string) {
    return axios.get(`${API_URL}/avito-tasks/task-file/${taskFile}`, authHeaders(token));
  },

  // RKN block ----------------------------------------------------------------
  async runRknChecker(token: string, data: IRknCheckerTaskCreate) {
    const params = authHeaders(token);
    const formData = new FormData();
    Object.keys(data).forEach((key) => formData.append(key, data[key]));
    return axios.post(`${API_URL}/rkn-checker/run`, formData, params);
  },
  async getRknCheckerFiles(token: string) {
    return axios.get<IRknCheckerFile[]>(`${API_URL}/rkn-checker/result/list`, authHeaders(token));
  },
  async getRknCheckerFile(token: string, filename: string) {
    return axios.get(`${API_URL}/rkn-checker/result/${filename}`, authHeaders(token));
  },
  async getRknCheckerStatus(token: string) {
    return axios.get(`${API_URL}/rkn-checker/status`, authHeaders(token));
  },
  async cancelRknChecker(token: string) {
    return axios.delete(`${API_URL}/rkn-checker/cancel`, authHeaders(token));
  },
  async delRknCheckerFile(token: string, filename: string) {
    return axios.delete(`${API_URL}/rkn-checker/result/${filename}`, authHeaders(token));
  },
  async delRknCheckerFiles(token: string) {
    return axios.delete(`${API_URL}/rkn-checker/result/list`, authHeaders(token));
  },

  // SiteParserTasks block ----------------------------------------------------------------
  async getSiteParserTasks(token: string) {
    return axios.get<ISiteParserTask[]>(`${API_URL}/${siteParserBaseEndpoint}/`, authHeaders(token));
  },
  async updateSiteParserTask(token: string, taskId: number, data: ISiteParserTaskUpdate) {
    return axios.put(`${API_URL}/${siteParserBaseEndpoint}/${taskId}`, data, authHeaders(token));
  },
  async createSiteParserTask(token: string, data: ISiteParserTaskCreate) {
    const { name, description, domains, mode } = data;
    const params = {
      ...authHeaders(token),
      params: { name, description, mode },
    };
    return axios.post(`${API_URL}/${siteParserBaseEndpoint}/`, domains, params);
  },
  async deleteSiteParserTask(token: string, taskId: number) {
    return axios.delete(`${API_URL}/${siteParserBaseEndpoint}/${taskId}`, authHeaders(token));
  },
  async runSiteParserTask(token: string, taskId: number) {
    return axios.post(`${API_URL}/${siteParserBaseEndpoint}/process/${taskId}`, {}, authHeaders(token));
  },
  async killSiteParserTask(token: string, taskId: number) {
    return axios.delete(`${API_URL}/${siteParserBaseEndpoint}/process/${taskId}`, authHeaders(token));
  },
  async statSiteParserTasks(token: string) {
    return axios.post(`${API_URL}/${siteParserBaseEndpoint}/process-stat`, {}, authHeaders(token));
  },
  async downloadResultSiteParserTask(token: string, id: number) {
    return axios.get(`${API_URL}/${siteParserBaseEndpoint}/${id}/result`, authHeaders(token));
  },
  async downloadSiteParserTaskFile(token: string, taskFile: string) {
    return axios.get(`${API_URL}/${siteParserBaseEndpoint}/task-file/${taskFile}`, authHeaders(token));
  },
  async getSiteParserDomainsV1(token: string) {
    return axios.get<string[]>(`${API_URL}/${siteParserBaseEndpoint}/domains`, authHeaders(token));
  },

  async getSiteParserDomains(token: string) {
    return axios.get<string[]>(`${apiUrl}/${siteParserConfigBaseEndpoint}/domains`, authHeaders(token));
  },
  async getSiteParserTemplates(token: string) {
    return axios.get<ISiteTemplate[]>(`${apiUrl}/${siteParserConfigBaseEndpoint}/`, authHeaders(token));
  },
  async getSiteParserTemplate(token: string, domain: string) {
    return axios.get<ISiteTemplate>(`${apiUrl}/${siteParserConfigBaseEndpoint}/${domain}`, authHeaders(token));
  },
  async createSiteParserTemplate(token: string, newDomainConfig: ISiteTemplateCreate) {
    return axios.post<ISiteTemplate>(`${apiUrl}/${siteParserConfigBaseEndpoint}/`, newDomainConfig, authHeaders(token));
  },
  async updateSiteParserTemplate(token: string, domain: string, updateDomainConfig: ISiteTemplateUpdate) {
    return axios.patch<ISiteTemplate>(
      `${apiUrl}/${siteParserConfigBaseEndpoint}/${domain}`,
      updateDomainConfig,
      authHeaders(token),
    );
  },
  async deleteSiteParserTemplate(token: string, domain: string) {
    return axios.delete<void>(`${apiUrl}/${siteParserConfigBaseEndpoint}/${domain}`, authHeaders(token));
  },

  // SiteTrafficTasks block ----------------------------------------------------------------
  async getSiteTrafficTasks(token: string) {
    return axios.get<ISiteTrafficTask[]>(`${API_URL}/${siteTrafficBaseEndpoint}/`, authHeaders(token));
  },
  async updateSiteTrafficTask(token: string, taskId: number, data: ISiteTrafficTaskUpdate) {
    return axios.put(`${API_URL}/${siteTrafficBaseEndpoint}/${taskId}`, data, authHeaders(token));
  },
  async createSiteTrafficTask(token: string, data: ISiteTrafficTaskCreate) {
    // const { name, description } = data;
    // const params = {
    //   ...authHeaders(token),
    //   params: { name, description },
    // };
    const params = authHeaders(token);
    const paramsKey = 'params';
    params[paramsKey] = {name: data.name, description: data.description};

    const formData = new FormData();
    Object.keys(data).forEach((key) => formData.append(key, data[key]));
    return axios.post(`${API_URL}/${siteTrafficBaseEndpoint}/`, formData, params);
  },
  async deleteSiteTrafficTask(token: string, taskId: number) {
    return axios.delete(`${API_URL}/${siteTrafficBaseEndpoint}/${taskId}`, authHeaders(token));
  },
  async runSiteTrafficTask(token: string, taskId: number) {
    return axios.post(`${API_URL}/${siteTrafficBaseEndpoint}/process/${taskId}`, {}, authHeaders(token));
  },
  async killSiteTrafficTask(token: string, taskId: number) {
    return axios.delete(`${API_URL}/${siteTrafficBaseEndpoint}/process/${taskId}`, authHeaders(token));
  },
  async statSiteTrafficTasks(token: string) {
    return axios.post(`${API_URL}/${siteTrafficBaseEndpoint}/process-stat`, {}, authHeaders(token));
  },
  async downloadResultSiteTrafficTask(token: string, id: number) {
    return axios.get(`${API_URL}/${siteTrafficBaseEndpoint}/${id}/result`, authHeaders(token));
  },
  async downloadSiteTrafficTaskFile(token: string, taskFile: string) {
    return axios.get(`${API_URL}/${siteTrafficBaseEndpoint}/task-file/${taskFile}`, authHeaders(token));
  },

  // WordstatTasks block ----------------------------------------------------------------
  async getWordstatTasks(token: string) {
    return axios.get<IWordstatTask[]>(`${API_URL}/${wordstatEndpoint}/`, authHeaders(token));
  },
  async updateWordstatTask(token: string, taskId: number, data: IWordstatTaskUpdate) {
    return axios.put(`${API_URL}/${wordstatEndpoint}/${taskId}`, data, authHeaders(token));
  },
  async createWordstatTask(token: string, data: IWordstatTaskCreate) {
    // const { name, description } = data;
    // const params = {
    //   ...authHeaders(token),
    //   params: { name, description },
    // };
    const params = authHeaders(token);
    const paramsKey = 'params';
    params[paramsKey] = {name: data.name, description: data.description};

    const formData = new FormData();
    Object.keys(data).forEach((key) => formData.append(key, data[key]));
    return axios.post(`${API_URL}/${wordstatEndpoint}/`, formData, params);
  },
  async deleteWordstatTask(token: string, taskId: number) {
    return axios.delete(`${API_URL}/${wordstatEndpoint}/${taskId}`, authHeaders(token));
  },
  async runWordstatTask(token: string, taskId: number) {
    return axios.post(`${API_URL}/${wordstatEndpoint}/process/${taskId}`, {}, authHeaders(token));
  },
  async killWordstatTask(token: string, taskId: number) {
    return axios.delete(`${API_URL}/${wordstatEndpoint}/process/${taskId}`, authHeaders(token));
  },
  async statWordstatTasks(token: string) {
    return axios.post(`${API_URL}/${wordstatEndpoint}/process-stat`, {}, authHeaders(token));
  },
  async downloadResultWordstatTask(token: string, id: number) {
    return axios.get(`${API_URL}/${wordstatEndpoint}/${id}/result`, authHeaders(token));
  },
  async downloadWordstatTaskFile(token: string, taskFile: string) {
    return axios.get(`${API_URL}/${wordstatEndpoint}/task-file/${taskFile}`, authHeaders(token));
  },

  // RegistrarTasks block ----------------------------------------------------------------
  async getRegistrarTasks(token: string) {
    return axios.get<IRegistrarTask[]>(`${API_URL}/${registrarEndpoint}/`, authHeaders(token));
  },
  async updateRegistrarTask(token: string, taskId: number, data: IRegistrarTaskUpdate) {
    return axios.put(`${API_URL}/${registrarEndpoint}/${taskId}`, data, authHeaders(token));
  },
  async createRegistrarTask(token: string, data: IRegistrarTaskCreate) {
    // const { name, description } = data;
    // const params = {
    //   ...authHeaders(token),
    //   params: { name, description },
    // };
    const params = authHeaders(token);
    const paramsKey = 'params';
    params[paramsKey] = {name: data.name, description: data.description};

    const formData = new FormData();
    Object.keys(data).forEach((key) => formData.append(key, data[key]));
    return axios.post(`${API_URL}/${registrarEndpoint}/`, formData, params);
  },
  async deleteRegistrarTask(token: string, taskId: number) {
    return axios.delete(`${API_URL}/${registrarEndpoint}/${taskId}`, authHeaders(token));
  },
  async runRegistrarTask(token: string, taskId: number) {
    return axios.post(`${API_URL}/${registrarEndpoint}/process/${taskId}`, {}, authHeaders(token));
  },
  async killRegistrarTask(token: string, taskId: number) {
    return axios.delete(`${API_URL}/${registrarEndpoint}/process/${taskId}`, authHeaders(token));
  },
  async statRegistrarTasks(token: string) {
    return axios.post(`${API_URL}/${registrarEndpoint}/process-stat`, {}, authHeaders(token));
  },
  async downloadResultRegistrarTask(token: string, id: number) {
    return axios.get(`${API_URL}/${registrarEndpoint}/${id}/result`, authHeaders(token));
  },
  async downloadRegistrarTaskFile(token: string, taskFile: string) {
    return axios.get(`${API_URL}/${registrarEndpoint}/task-file/${taskFile}`, authHeaders(token));
  },

};
