export interface ITelegramReportFile {
  id: number;
  filename: string;
  modified_date: string;
  year?: number;
  month?: number;
}

export interface ITelegramState {
  status: number | null;
  total: number | null;
  done: number | null;
}

export const telegramInitialState: ITelegramState = {
  status: null,
  total: null,
  done: null,
};
