import axios from 'axios';
import { getStoreAccessors } from 'typesafe-vuex';
import { ActionContext } from 'vuex';

import { api } from '@/api';
import { TelegramState } from './state';
import {
  commitSetTelegramFinished,
  commitSetTelegramState,
} from './mutations';
import { dispatchCheckApiError } from '../main/actions';
import { State } from '../state';

type MainContext = ActionContext<TelegramState, State>;

export const actions = {
  async actionGetTelegramStatus(context: MainContext) {
    try {
      const { data } = await api.getTelegramStatus(context.rootState.main.token);
      commitSetTelegramState(context, data);
      return data;
    } catch (error) {
      if (axios.isAxiosError(error)) await dispatchCheckApiError(context, error);
    }
  },
  async actionRunTelegram(context: MainContext) {
    try {
      const { data } = await api.runTelegram(context.rootState.main.token);
      return data;
    } catch (error) {
      if (axios.isAxiosError(error)) await dispatchCheckApiError(context, error);
    }
  },
  async actionCancelTelegram(context: MainContext) {
    try {
      await api.cancelTelegram(context.rootState.main.token);
      commitSetTelegramFinished(context, true);
      return true;
    } catch (error) {
      if (axios.isAxiosError(error)) await dispatchCheckApiError(context, error);
    }
  },
};

const { dispatch } = getStoreAccessors<TelegramState, State>('');

export const dispatchGetTelegramStatus = dispatch(actions.actionGetTelegramStatus);
export const dispatchRunTelegram = dispatch(actions.actionRunTelegram);
export const dispatchCancelTelegram = dispatch(actions.actionCancelTelegram);
