import { mutations } from './mutations';
import { getters } from './getters';
import { actions } from './actions';
import { TgTaskState } from './state';

const defaultState: TgTaskState = {
  tg_tasks: [],
};

export const tgTaskModule = {
  state: defaultState,
  mutations,
  actions,
  getters,
};
