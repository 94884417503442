import { getStoreAccessors } from 'typesafe-vuex';

import { WordstatTaskState } from './state';
import { State } from '../state';
import { IWordstatTask } from '@/interfaces/wordstat';

export const getters = {
  WordstatTasks: (state: WordstatTaskState) => state.tasks,
  oneWordstatTask: (state: WordstatTaskState) => (taskId: number) => state.tasks.find(
    (task: IWordstatTask) => task.id === taskId),
};

const { read } = getStoreAccessors<WordstatTaskState, State>('');

export const readOneWordstatTask = read(getters.oneWordstatTask);
export const readWordstatTasks = read(getters.WordstatTasks);
