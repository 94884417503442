import { getStoreAccessors } from 'typesafe-vuex';

import { SiteTrafficTaskState } from './state';
import { State } from '../state';
import { ISiteTrafficTask } from '@/interfaces/site-traffic';

export const getters = {
  SiteTrafficTasks: (state: SiteTrafficTaskState) => state.tasks,
  oneSiteTrafficTask: (state: SiteTrafficTaskState) => (taskId: number) => state.tasks.find(
    (task: ISiteTrafficTask) => task.id === taskId),
};

const { read } = getStoreAccessors<SiteTrafficTaskState, State>('');

export const readOneSiteTrafficTask = read(getters.oneSiteTrafficTask);
export const readSiteTrafficTasks = read(getters.SiteTrafficTasks);
