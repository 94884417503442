import { AxiosError } from 'axios';
import { getStoreAccessors } from 'typesafe-vuex';
import { ActionContext } from 'vuex';

import { api } from '@/api';
import { API_DOMAIN } from '@/constants';
import { apiUrl } from '@/env';
import { ISiteParserTaskCreate, ISiteParserTaskUpdate } from '@/interfaces/site-parser';
import { delay } from '@/utils';
import { dispatchCheckApiError } from '../main/actions';
import { commitAddNotification, commitRemoveNotification } from '../main/mutations';
import { State } from '../state';
import { readOneSiteParserTask } from './getters';
import {
  commitSetSiteParserDomains,
  commitSetSiteParserTask, commitSetSiteParserTaskIsDownloading, commitSetSiteParserTasks,
  commitSetSiteParserTasksStat, commitSetSiteParserTaskStatus,
} from './mutations';
import { SiteParserTaskState } from './state';
import { authHeaders, downloadFile, withDefaultValue } from '../store-utils';

type MainContext = ActionContext<SiteParserTaskState, State>;

const MAX_FAILED_ATTEMPTS = 2 * 60; // 60 minutes
const ATTEMPTS_INTERVAL = 30 * 1000;  // 30 seconds
const API_URL = apiUrl + API_DOMAIN;
const DOWNLOAD_ONCE = true;

const utils = {
  counter: withDefaultValue({}),  // to control the number of failed attempts

  async downloadSiteParserTaskResult(context: MainContext, payload: { id: number, result: string }, once = false) {
    const url = `${API_URL}/site-parser/${payload.id}/result`;
    const { token } = context.rootState.main;
    const { id, result } = payload;

    const updateStatuses = () => {
      commitSetSiteParserTaskIsDownloading(context, { id, isDownloading: false});
      commitSetSiteParserTaskStatus(context, { id, status: 'ENDED' });
    };

    if (once) {
      commitSetSiteParserTaskIsDownloading(context, { id, isDownloading: true });
      commitSetSiteParserTaskStatus(context, { id, status: 'DOWNLOAD' });
    } else {
      const updatedTask = readOneSiteParserTask(context)(id);
      if (updatedTask?.status !== 'EXPORT' && !updatedTask?.result?.endsWith('.zip')) {
        delete this.counter[id];
        return;
      }
    }

    // на случай, если захотим брать название файла отчта из store
    // const result = context.state.se_tasks.find((YtTask => YtTask.id === payload.id))?.result;
    // console.log('task.result --> ', result);

    try {
      const response = await fetch(url, authHeaders(token));
      if (response.status !== 200) throw new Error(`Response status: ${response.status}`);
      const bytes = await response.blob();
      delete this.counter[id];
      downloadFile(bytes, result);
      commitAddNotification(context, { content: 'Файл отчета успешно загружен', color: 'success' });
      updateStatuses();
    } catch (error) {
      if (once) {
        // console.log('Ошибка загрузки файла:', error);
        commitAddNotification(context, {
          content: 'Ошибка загрузки файла',
          color: 'error',
        });
        updateStatuses();
      } else if (this.counter[id] < MAX_FAILED_ATTEMPTS) {
          setTimeout(() => this.downloadSiteParserTaskResult(context, payload), ATTEMPTS_INTERVAL);
          this.counter[id]++;
        } else {
          delete this.counter[id];
          commitAddNotification(context, {
            content: 'Превышено максимальное количество попыток скачивания файла. Операция отменена',
            color: 'error' });
          updateStatuses();
        }
    }
  },
};


export const actions = {
  async actionGetSiteParserTasks(context: MainContext) {
    try {
      const { data } = await api.getSiteParserTasks(context.rootState.main.token);
      commitSetSiteParserTasks(context, data);
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },

  async actionUpdateSiteParserTask(context: MainContext, payload: { id: number, task: ISiteParserTaskUpdate }) {
    const { token } = context.rootState.main;
    const { id, task } = payload;
    try {
      const loadingNotification = { content: 'Сохранение', showProgress: true };
      commitAddNotification(context, loadingNotification);
      const [{ data }] = await Promise.all([api.updateSiteParserTask(token, id, task), delay(500)]);
      commitSetSiteParserTask(context, data);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, { content: 'Задача успешно обновлена', color: 'success' });
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },

  async actionCreateSiteParserTask(context: MainContext, payload: ISiteParserTaskCreate) {
    const { token } = context.rootState.main;
    try {
      const loadingNotification = { content: 'Создание', showProgress: true };
      commitAddNotification(context, loadingNotification);
      const [{ data }] = await Promise.all([api.createSiteParserTask(token, payload), delay(500)]);
      commitSetSiteParserTask(context, data);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, { content: 'Задача успешно создана', color: 'success' });
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },

  async actionDeleteSiteParserTask(context: MainContext, payload: { id: number }) {
    const { token } = context.rootState.main;
    try {
      const loadingNotification = { content: 'Удаление', showProgress: true };
      commitAddNotification(context, loadingNotification);
      const [{ data }] = await Promise.all([api.deleteSiteParserTask(token, payload.id), delay(500)]);
      commitSetSiteParserTask(context, data);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, { content: 'Задача успешно удалена', color: 'success' });
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },

  async actionRunSiteParserTask(context: MainContext, payload: { id: number }) {
    const { token } = context.rootState.main;
    const { id } = payload;
    try {
      const loadingNotification = { content: 'Запуск', showProgress: true };
      commitAddNotification(context, loadingNotification);
      await Promise.all([api.runSiteParserTask(token, id), delay(500)]);
      commitSetSiteParserTaskStatus(context, { id, status: 'ACTIVE' });
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, { content: 'Задача успешно запущена', color: 'success' });
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },

  async actionKillSiteParserTask(context: MainContext, payload: { id: number }) {
    const { token } = context.rootState.main;
    try {
      const loadingNotification = { content: 'Прерывание', showProgress: true };
      commitAddNotification(context, loadingNotification);
      const [{ data }] = await Promise.all([api.killSiteParserTask(token, payload.id), delay(500)]);
      commitSetSiteParserTask(context, data);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, { content: 'Задача успешно прервана', color: 'success' });
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },

  async actionStatSiteParserTasks(context: MainContext) {
    const { token } = context.rootState.main;
    try {
      const { data } = await api.statSiteParserTasks(token);
      commitSetSiteParserTasksStat(context, data);
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },

  // загружаем файл отчета
  async actionDownloadSiteParserTaskResult(context: MainContext, payload: { id: number, result: string }) {
    utils.downloadSiteParserTaskResult(context, payload, DOWNLOAD_ONCE);
  },

  async actionDownloadSiteParserTaskFile(context: MainContext, payload: { taskFile: string }) {
    const { taskFile } = payload;
    const url = `${API_URL}/plugs-checker/task-file/${taskFile}`;
    const { token } = context.rootState.main;

    try {
      const response = await fetch(url, authHeaders(token));
      if (response.status !== 200) throw new Error(`Response status: ${response.status}`);
      const bytes = await response.blob();
      downloadFile(bytes, taskFile);
      commitAddNotification(context, { content: 'Файл отчета успешно загружен', color: 'success' });
    } catch (error) {
        commitAddNotification(context, {
          content: 'Ошибка загрузки файла',
          color: 'error',
        });
    }
  },

  async actionGetSiteParserDomains(context: MainContext) {
    try {
      const { data } = await api.getSiteParserDomains(context.rootState.main.token);
      // console.log({ data });
      commitSetSiteParserDomains(context, data);
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },

};

const { dispatch } = getStoreAccessors<SiteParserTaskState, State>('');

export const dispatchCreateSiteParserTask = dispatch(actions.actionCreateSiteParserTask);
export const dispatchGetSiteParserTasks = dispatch(actions.actionGetSiteParserTasks);
export const dispatchUpdateSiteParserTask = dispatch(actions.actionUpdateSiteParserTask);
export const dispatchDeleteSiteParserTask = dispatch(actions.actionDeleteSiteParserTask);
export const dispatchRunSiteParserTask = dispatch(actions.actionRunSiteParserTask);
export const dispatchKillSiteParserTask = dispatch(actions.actionKillSiteParserTask);
export const dispatchStatSiteParserTasks = dispatch(actions.actionStatSiteParserTasks);
export const dispatchDownloadSiteParserTaskResult = dispatch(actions.actionDownloadSiteParserTaskResult);
export const dispatchDownloadSiteParserTaskFile = dispatch(actions.actionDownloadSiteParserTaskFile);
export const dispatchGetSiteParserDomains = dispatch(actions.actionGetSiteParserDomains);
