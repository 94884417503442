import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';
import { ClaimState } from './state';

export const getters = {
    claimFinished: (state: ClaimState) => state.finished,
    claimState: (state: ClaimState) => state.state,
};

const { read } = getStoreAccessors<ClaimState, State>('');

export const readClaimFinished = read(getters.claimFinished);
export const readClaimState = read(getters.claimState);
