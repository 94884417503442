import { mutations } from './mutations';
import { getters } from './getters';
import { actions } from './actions';
import { TelegramState } from './state';
import { telegramInitialState } from '@/interfaces/telegram';

const defaultState: TelegramState = {
  finished: false,
  state: telegramInitialState,
};

export const telegramModule = {
  state: defaultState,
  mutations,
  actions,
  getters,
};
