import { getStoreAccessors } from 'typesafe-vuex';

import { State } from '../state';
import { TelegramState } from './state';

export const getters = {
  telegramFinished: (state: TelegramState) => state.finished,
  telegramState: (state: TelegramState) => state.state,
};

const { read } = getStoreAccessors<TelegramState, State>('');

export const readTelegramFinished = read(getters.telegramFinished);
export const readTelegramState = read(getters.telegramState);
