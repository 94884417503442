export interface IRknCheckerFile {
    id: number;
    filename: string;
    modified_date: string;
    year?: number;
    month?: number;
}

export interface IRknCheckerState {
    status: number | null;
    total: number | null;
    done: number | null;
    message?: string;
}

export const rknCheckerInitialState: IRknCheckerState = {
    status: null,
    total: null,
    done: null,
};

export interface IRknCheckerTaskCreate {
    file: File|null;
}
