import { AxiosError } from 'axios';
import { getStoreAccessors } from 'typesafe-vuex';
import { ActionContext } from 'vuex';

import { api } from '@/api';
import { API_DOMAIN } from '@/constants';
import { apiUrl } from '@/env';
import { IAvitoTaskCreate, IAvitoTaskUpdate } from '@/interfaces/avito-tasks';
import { delay } from '@/utils';
import { dispatchCheckApiError } from '../main/actions';
import { commitAddNotification, commitRemoveNotification } from '../main/mutations';
import { State } from '../state';
import { readOneAvitoTask } from './getters';
import {
  commitSetAvitoTask, commitSetAvitoTaskIsDownloading, commitSetAvitoTasks,
  commitSetAvitoTasksStat, commitSetAvitoTaskStatus,
} from './mutations';
import { AvitoTaskState } from './state';
import { authHeaders, downloadFile, withDefaultValue } from '../store-utils';

type MainContext = ActionContext<AvitoTaskState, State>;

const MAX_FAILED_ATTEMPTS = 2 * 60; // 60 minutes
const ATTEMPTS_INTERVAL = 30 * 1000;  // 30 seconds
const API_URL = apiUrl + API_DOMAIN;
const DOWNLOAD_ONCE = true;

const utils = {
  counter: withDefaultValue({}),  // to control the number of failed attempts

  async downloadAvitoTaskResult(context: MainContext, payload: { id: number, result: string }, once = false) {
    const url = `${API_URL}/avito-tasks/${payload.id}/result`;
    const { token } = context.rootState.main;
    const { id, result } = payload;

    const updateStatuses = () => {
      commitSetAvitoTaskIsDownloading(context, { id, isDownloading: false});
      commitSetAvitoTaskStatus(context, { id, status: 'ENDED' });
    };

    if (once) {
      commitSetAvitoTaskIsDownloading(context, { id, isDownloading: true });
      commitSetAvitoTaskStatus(context, { id, status: 'DOWNLOAD' });
    } else {
      const updatedTask = readOneAvitoTask(context)(id);
      if (updatedTask?.status !== 'EXPORT' && !updatedTask?.result?.endsWith('.zip')) {
        delete this.counter[id];
        return;
      }
    }

    // на случай, если захотим брать название файла отчта из store
    // const result = context.state.se_tasks.find((YtTask => YtTask.id === payload.id))?.result;
    // console.log('task.result --> ', result);

    try {
      const response = await fetch(url, authHeaders(token));
      if (response.status !== 200) throw new Error(`Response status: ${response.status}`);
      const bytes = await response.blob();
      delete this.counter[id];
      downloadFile(bytes, result);
      commitAddNotification(context, { content: 'Файл отчета успешно загружен', color: 'success' });
      updateStatuses();
    } catch (error) {
      if (once) {
        // console.log('Ошибка загрузки файла:', error);
        commitAddNotification(context, {
          content: 'Ошибка загрузки файла',
          color: 'error',
        });
        updateStatuses();
      } else if (this.counter[id] < MAX_FAILED_ATTEMPTS) {
          setTimeout(() => this.downloadAvitoTaskResult(context, payload), ATTEMPTS_INTERVAL);
          this.counter[id]++;
        } else {
          delete this.counter[id];
          commitAddNotification(context, {
            content: 'Превышено максимальное количество попыток скачивания файла. Операция отменена',
            color: 'error' });
          updateStatuses();
        }
    }
  },
};


export const actions = {
  async actionGetAvitoTasks(context: MainContext) {
    try {
      const { data } = await api.getAvitoTasks(context.rootState.main.token);
      commitSetAvitoTasks(context, data);
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },

  async actionUpdateAvitoTask(context: MainContext, payload: { id: number, task: IAvitoTaskUpdate }) {
    const { token } = context.rootState.main;
    const { id, task } = payload;
    try {
      const loadingNotification = { content: 'Сохранение', showProgress: true };
      commitAddNotification(context, loadingNotification);
      const [{ data }] = await Promise.all([api.updateAvitoTask(token, id, task), delay(500)]);
      commitSetAvitoTask(context, data);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, { content: 'Задача успешно обновлена', color: 'success' });
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },

  async actionCreateAvitoTask(context: MainContext, payload: IAvitoTaskCreate) {
    const { token } = context.rootState.main;
    try {
      const loadingNotification = { content: 'Создание', showProgress: true };
      commitAddNotification(context, loadingNotification);
      const [{ data }] = await Promise.all([api.createAvitoTask(token, payload), delay(500)]);
      commitSetAvitoTask(context, data);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, { content: 'Задача успешно создана', color: 'success' });
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },

  async actionDeleteAvitoTask(context: MainContext, payload: { id: number }) {
    const { token } = context.rootState.main;
    try {
      const loadingNotification = { content: 'Удаление', showProgress: true };
      commitAddNotification(context, loadingNotification);
      const [{ data }] = await Promise.all([api.deleteAvitoTask(token, payload.id), delay(500)]);
      commitSetAvitoTask(context, data);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, { content: 'Задача успешно удалена', color: 'success' });
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },

  async actionRunAvitoTask(context: MainContext, payload: { id: number }) {
    const { token } = context.rootState.main;
    const { id } = payload;
    try {
      const loadingNotification = { content: 'Запуск', showProgress: true };
      commitAddNotification(context, loadingNotification);
      await Promise.all([api.runAvitoTask(token, id), delay(500)]);
      commitSetAvitoTaskStatus(context, { id, status: 'ACTIVE' });
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, { content: 'Задача успешно запущена', color: 'success' });
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },

  async actionKillAvitoTask(context: MainContext, payload: { id: number }) {
    const { token } = context.rootState.main;
    try {
      const loadingNotification = { content: 'Прерывание', showProgress: true };
      commitAddNotification(context, loadingNotification);
      const [{ data }] = await Promise.all([api.killAvitoTask(token, payload.id), delay(500)]);
      commitSetAvitoTask(context, data);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, { content: 'Задача успешно прервана', color: 'success' });
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },

  async actionStatAvitoTasks(context: MainContext) {
    const { token } = context.rootState.main;
    try {
      const { data } = await api.statAvitoTasks(token);
      commitSetAvitoTasksStat(context, data);
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },

  // загружаем файл отчета
  async actionDownloadAvitoTaskResult(context: MainContext, payload: { id: number, result: string }) {
    utils.downloadAvitoTaskResult(context, payload, DOWNLOAD_ONCE);
  },

  async actionDownloadAvitoTaskFile(context: MainContext, payload: { taskFile: string }) {
    const { taskFile } = payload;
    const url = `${API_URL}/avito-tasks/task-file/${taskFile}`;
    const { token } = context.rootState.main;

    try {
      const response = await fetch(url, authHeaders(token));
      if (response.status !== 200) throw new Error(`Response status: ${response.status}`);
      const bytes = await response.blob();
      downloadFile(bytes, taskFile);
      commitAddNotification(context, { content: 'Файл отчета успешно загружен', color: 'success' });
    } catch (error) {
        commitAddNotification(context, {
          content: 'Ошибка загрузки файла',
          color: 'error',
        });
    }

  },
};

const { dispatch } = getStoreAccessors<AvitoTaskState, State>('');

export const dispatchCreateAvitoTask = dispatch(actions.actionCreateAvitoTask);
export const dispatchGetAvitoTasks = dispatch(actions.actionGetAvitoTasks);
export const dispatchUpdateAvitoTask = dispatch(actions.actionUpdateAvitoTask);
export const dispatchDeleteAvitoTask = dispatch(actions.actionDeleteAvitoTask);
export const dispatchRunAvitoTask = dispatch(actions.actionRunAvitoTask);
export const dispatchKillAvitoTask = dispatch(actions.actionKillAvitoTask);
export const dispatchStatAvitoTasks = dispatch(actions.actionStatAvitoTasks);
export const dispatchDownloadAvitoTaskResult = dispatch(actions.actionDownloadAvitoTaskResult);
export const dispatchDownloadAvitoTaskFile = dispatch(actions.actionDownloadAvitoTaskFile);
