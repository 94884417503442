
const env = process.env.VUE_APP_ENV;

let envApiUrl = '';
let envWsUrl = '';

switch (env) {
  case 'production': {
    envApiUrl = `https://${process.env.VUE_APP_DOMAIN_PROD}`;
    envWsUrl = `ws://${process.env.VUE_APP_DOMAIN_PROD}`;
    break;
  }
  case 'staging': {
    envApiUrl = `https://${process.env.VUE_APP_DOMAIN_STAG}`;
    envWsUrl = `ws://${process.env.VUE_APP_DOMAIN_STAG}`;
    break;
  }
  default: {
    envApiUrl = `http://${process.env.VUE_APP_DOMAIN_DEV}`;
    envWsUrl = `ws://${process.env.VUE_APP_DOMAIN_DEV}`;
  }
}

export const apiUrl = envApiUrl;
export const wsUrl = envWsUrl;
export const appName = process.env.VUE_APP_NAME;
