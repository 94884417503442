// to set default value to a missing item in an object
export const withDefaultValue = (target: object, defaultValue = 0) => new Proxy(target, {
  get: (obj, prop) => prop in obj ? obj[prop] : defaultValue,
});

export const downloadFile = (fileContent: Blob, fileName: string) => {
  const link = document.createElement('a');
  link.href = URL.createObjectURL(fileContent);
  link.setAttribute('download', fileName);
  link.click();
  URL.revokeObjectURL(link.href);
};

export const authHeaders = (token: string) => ({ headers: {Authorization: `Bearer ${token}`} });
