import { mutations } from './mutations';
import { getters } from './getters';
import { actions } from './actions';
import { RknCheckerState } from './state';
import { rknCheckerInitialState } from '@/interfaces/rkn-checker';

const defaultState: RknCheckerState = {
  finished: false,
  state: rknCheckerInitialState,
};

export const rknCheckerModule = {
  state: defaultState,
  mutations,
  actions,
  getters,
};
