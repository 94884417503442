import { BrowserState, IFolder } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';
import { IFile } from '@/interfaces/file-browser';

export const mutations = {
  setBrowserFiles(state: BrowserState, payload: { files: IFile[], workDir: string }) {
    const folder = state.folders.find((fd: IFolder) => (fd.name === payload.workDir));
    if (folder) folder.files = payload.files;
    else {
      const newFolder = { name: payload.workDir, files: payload.files };
      state.folders.push(newFolder);
    }

  },
  clearBrowserFiles(state: BrowserState, payload: string) {
    const folder = state.folders.find((fd: IFolder) => fd.name === payload);
    if (folder) folder.files = [];
  },
  delBrowserFile(state: BrowserState, payload: { workDir: string, filename: string}) {
    const folder = state.folders.find((fd: IFolder) => (fd.name === payload.workDir));
    if (folder)
      folder.files = folder.files
        .filter((el: IFile) => el.filename !== payload.filename);
  },
};

const { commit } = getStoreAccessors<BrowserState, State>('');

export const commitSetBrowserFiles = commit(mutations.setBrowserFiles);
export const commitClearBrowserFiles = commit(mutations.clearBrowserFiles);
export const commitDelBrowserFile = commit(mutations.delBrowserFile);
