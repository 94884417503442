import { AxiosError } from 'axios';
import { getStoreAccessors } from 'typesafe-vuex';
import { ActionContext } from 'vuex';

import { api } from '@/api';
import { API_DOMAIN } from '@/constants';
import { apiUrl } from '@/env';
import { IWordstatTaskCreate, IWordstatTaskUpdate } from '@/interfaces/wordstat';
import { delay } from '@/utils';
import { dispatchCheckApiError } from '../main/actions';
import { commitAddNotification, commitRemoveNotification } from '../main/mutations';
import { State } from '../state';
import { readOneWordstatTask } from './getters';
import {
  commitSetWordstatTask, commitSetWordstatTaskIsDownloading, commitSetWordstatTasks,
  commitSetWordstatTasksStat, commitSetWordstatTaskStatus,
} from './mutations';
import { WordstatTaskState } from './state';
import { authHeaders, downloadFile, withDefaultValue } from '../store-utils';

type MainContext = ActionContext<WordstatTaskState, State>;

const MAX_FAILED_ATTEMPTS = 2 * 60; // 60 minutes
const ATTEMPTS_INTERVAL = 30 * 1000;  // 30 seconds
const API_URL = apiUrl + API_DOMAIN;
const DOWNLOAD_ONCE = true;

const utils = {
  counter: withDefaultValue({}),  // to control the number of failed attempts

  async downloadWordstatTaskResult(context: MainContext, payload: { id: number, result: string }, once = false) {
    const url = `${API_URL}/wordstat/${payload.id}/result`;
    const { token } = context.rootState.main;
    const { id, result } = payload;

    const updateStatuses = () => {
      commitSetWordstatTaskIsDownloading(context, { id, isDownloading: false});
      commitSetWordstatTaskStatus(context, { id, status: 'ENDED' });
    };

    if (once) {
      commitSetWordstatTaskIsDownloading(context, { id, isDownloading: true });
      commitSetWordstatTaskStatus(context, { id, status: 'DOWNLOAD' });
    } else {
      const updatedTask = readOneWordstatTask(context)(id);
      if (updatedTask?.status !== 'EXPORT' && !updatedTask?.result?.endsWith('.zip')) {
        delete this.counter[id];
        return;
      }
    }

    // на случай, если захотим брать название файла отчта из store
    // const result = context.state.se_tasks.find((YtTask => YtTask.id === payload.id))?.result;
    // console.log('task.result --> ', result);

    try {
      const response = await fetch(url, authHeaders(token));
      if (response.status !== 200) throw new Error(`Response status: ${response.status}`);
      const bytes = await response.blob();
      delete this.counter[id];
      downloadFile(bytes, result);
      commitAddNotification(context, { content: 'Файл отчета успешно загружен', color: 'success' });
      updateStatuses();
    } catch (error) {
      if (once) {
        // console.log('Ошибка загрузки файла:', error);
        commitAddNotification(context, {
          content: 'Ошибка загрузки файла',
          color: 'error',
        });
        updateStatuses();
      } else if (this.counter[id] < MAX_FAILED_ATTEMPTS) {
          setTimeout(() => this.downloadWordstatTaskResult(context, payload), ATTEMPTS_INTERVAL);
          this.counter[id]++;
        } else {
          delete this.counter[id];
          commitAddNotification(context, {
            content: 'Превышено максимальное количество попыток скачивания файла. Операция отменена',
            color: 'error' });
          updateStatuses();
        }
    }
  },
};


export const actions = {
  async actionGetWordstatTasks(context: MainContext) {
    try {
      const { data } = await api.getWordstatTasks(context.rootState.main.token);
      commitSetWordstatTasks(context, data);
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },

  async actionUpdateWordstatTask(context: MainContext, payload: { id: number, task: IWordstatTaskUpdate }) {
    const { token } = context.rootState.main;
    const { id, task } = payload;
    try {
      const loadingNotification = { content: 'Сохранение', showProgress: true };
      commitAddNotification(context, loadingNotification);
      const [{ data }] = await Promise.all([api.updateWordstatTask(token, id, task), delay(500)]);
      commitSetWordstatTask(context, data);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, { content: 'Задача успешно обновлена', color: 'success' });
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },

  async actionCreateWordstatTask(context: MainContext, payload: IWordstatTaskCreate) {
    const { token } = context.rootState.main;
    try {
      const loadingNotification = { content: 'Создание', showProgress: true };
      commitAddNotification(context, loadingNotification);
      const [{ data }] = await Promise.all([api.createWordstatTask(token, payload), delay(500)]);
      commitSetWordstatTask(context, data);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, { content: 'Задача успешно создана', color: 'success' });
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },

  async actionDeleteWordstatTask(context: MainContext, payload: { id: number }) {
    const { token } = context.rootState.main;
    try {
      const loadingNotification = { content: 'Удаление', showProgress: true };
      commitAddNotification(context, loadingNotification);
      const [{ data }] = await Promise.all([api.deleteWordstatTask(token, payload.id), delay(500)]);
      commitSetWordstatTask(context, data);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, { content: 'Задача успешно удалена', color: 'success' });
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },

  async actionRunWordstatTask(context: MainContext, payload: { id: number }) {
    const { token } = context.rootState.main;
    const { id } = payload;
    try {
      const loadingNotification = { content: 'Запуск', showProgress: true };
      commitAddNotification(context, loadingNotification);
      await Promise.all([api.runWordstatTask(token, id), delay(500)]);
      commitSetWordstatTaskStatus(context, { id, status: 'ACTIVE' });
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, { content: 'Задача успешно запущена', color: 'success' });
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },

  async actionKillWordstatTask(context: MainContext, payload: { id: number }) {
    const { token } = context.rootState.main;
    try {
      const loadingNotification = { content: 'Прерывание', showProgress: true };
      commitAddNotification(context, loadingNotification);
      const [{ data }] = await Promise.all([api.killWordstatTask(token, payload.id), delay(500)]);
      commitSetWordstatTask(context, data);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, { content: 'Задача успешно прервана', color: 'success' });
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },

  async actionStatWordstatTasks(context: MainContext) {
    const { token } = context.rootState.main;
    try {
      const { data } = await api.statWordstatTasks(token);
      commitSetWordstatTasksStat(context, data);
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },

  // загружаем файл отчета
  async actionDownloadWordstatTaskResult(context: MainContext, payload: { id: number, result: string }) {
    utils.downloadWordstatTaskResult(context, payload, DOWNLOAD_ONCE);
  },

  async actionDownloadWordstatTaskFile(context: MainContext, payload: { taskFile: string }) {
    const { taskFile } = payload;
    const url = `${API_URL}/wordstat/task-file/${taskFile}`;
    const { token } = context.rootState.main;

    try {
      const response = await fetch(url, authHeaders(token));
      if (response.status !== 200) throw new Error(`Response status: ${response.status}`);
      const bytes = await response.blob();
      downloadFile(bytes, taskFile);
      commitAddNotification(context, { content: 'Файл отчета успешно загружен', color: 'success' });
    } catch (error) {
        commitAddNotification(context, {
          content: 'Ошибка загрузки файла',
          color: 'error',
        });
    }
  },

};

const { dispatch } = getStoreAccessors<WordstatTaskState, State>('');

export const dispatchCreateWordstatTask = dispatch(actions.actionCreateWordstatTask);
export const dispatchGetWordstatTasks = dispatch(actions.actionGetWordstatTasks);
export const dispatchUpdateWordstatTask = dispatch(actions.actionUpdateWordstatTask);
export const dispatchDeleteWordstatTask = dispatch(actions.actionDeleteWordstatTask);
export const dispatchRunWordstatTask = dispatch(actions.actionRunWordstatTask);
export const dispatchKillWordstatTask = dispatch(actions.actionKillWordstatTask);
export const dispatchStatWordstatTasks = dispatch(actions.actionStatWordstatTasks);
export const dispatchDownloadWordstatTaskResult = dispatch(actions.actionDownloadWordstatTaskResult);
export const dispatchDownloadWordstatTaskFile = dispatch(actions.actionDownloadWordstatTaskFile);
