import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';
import { RknCheckerState } from './state';

export const getters = {
    rknCheckerFinished: (state: RknCheckerState) => state.finished,
    RknCheckerState: (state: RknCheckerState) => state.state,
};

const { read } = getStoreAccessors<RknCheckerState, State>('');

export const readRknCheckerFinished = read(getters.rknCheckerFinished);
export const readRknCheckerState = read(getters.RknCheckerState);
