import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';
import { MatrixState } from './state';

export const getters = {
    matrixFinished: (state: MatrixState) => state.finished,
    matrixState: (state: MatrixState) => state.state,
};

const { read } = getStoreAccessors<MatrixState, State>('');

export const readMatrixFinished = read(getters.matrixFinished);
export const readMatrixState = read(getters.matrixState);
