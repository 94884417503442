import { getStoreAccessors } from 'typesafe-vuex';

import { SiteParserTemplateState } from './state';
import { State } from '../state';
import { ISiteTemplate } from '@/interfaces/site-parser-template';

export const getters = {
  SiteParserTemplates: (state: SiteParserTemplateState) => state.templates,
  oneSiteParserTemplate: (state: SiteParserTemplateState) => (domain: string) => state.templates.find(
    (template: ISiteTemplate) => template.domain === domain),
};

const { read } = getStoreAccessors<SiteParserTemplateState, State>('');

export const readOneSiteParserTemplate = read(getters.oneSiteParserTemplate);
export const readSiteParserTemplates = read(getters.SiteParserTemplates);
