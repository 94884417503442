import { getStoreAccessors } from 'typesafe-vuex';

import { SiteParserTaskState } from './state';
import { State } from '../state';
import { ISiteParserTask } from '@/interfaces/site-parser';

export const getters = {
  SiteParserTasks: (state: SiteParserTaskState) => state.tasks,
  oneSiteParserTask: (state: SiteParserTaskState) => (taskId: number) => state.tasks.find(
    (task: ISiteParserTask) => task.id === taskId),
  SiteParserDomains: (state: SiteParserTaskState) => state.domains,
};

const { read } = getStoreAccessors<SiteParserTaskState, State>('');

export const readOneSiteParserTask = read(getters.oneSiteParserTask);
export const readSiteParserTasks = read(getters.SiteParserTasks);
export const readSiteParserDomains = read(getters.SiteParserDomains);
