import { PlugsCheckerTaskState } from './state';
import { apiUrl } from '@/env';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';
import { IPlugsCheckerTask, IPlugsCheckerOneTaskStat, IPlugsCheckerTasksStatData } from '@/interfaces/plugs-checker-tasks';


export const mutations = {
  setPlugsCheckerTasks(state: PlugsCheckerTaskState, payload: IPlugsCheckerTask[]) {
    // добавляем флаг isExporting; его пока нет в PG
    // если isExporting = true, иконка загрузки файлов заблокирована
    payload.forEach((item) => {
      item.isExporting = state.tasks.find((task) => task.id === item.id)?.isExporting;
      item.isDownloading = state.tasks.find((task) => task.id === item.id)?.isDownloading;
      if (item.status === 'ACTIVE') {
        const taskActive = state.tasks.find((t) => t.id === item.id);
        if (taskActive && taskActive.stats) item.stats = taskActive.stats;
      }
      if (item.isExporting) item.status = 'EXPORT';
      else if (item.isDownloading) item.status = 'DOWNLOAD';
    });
    state.tasks = payload;
  },
  setPlugsCheckerTask(state: PlugsCheckerTaskState, payload: IPlugsCheckerTask) {

    // const newList: IPlugsCheckerTask[] = state.yt_tasks.slice();
    // const index = newList.findIndex(item => item.id === payload.id);
    // if (index !== -1) {
    //     newList[index] = payload;
    //     state.yt_tasks = newList;
    // };

    // самый быстрый вариант
    const newList: IPlugsCheckerTask[] = state.tasks.slice();
    for (let i = 0; i < newList.length; i++)
      if (newList[i].id === payload.id) {
        newList[i] = payload;
        break;
      }
    state.tasks = newList;
  },

  // изменяем флаг isExporting
  setPlugsCheckerTaskIsExporting(state: PlugsCheckerTaskState, payload: { id: number, isExporting: boolean}) {
      const task = state.tasks.find((item: IPlugsCheckerTask) => item.id === payload.id);
      if (task) task.isExporting = payload.isExporting;
  },

  // изменяем флаг isDownloading
  setPlugsCheckerTaskIsDownloading(state: PlugsCheckerTaskState, payload: { id: number, isDownloading: boolean}) {
      const task = state.tasks.find((item: IPlugsCheckerTask) => item.id === payload.id);
      if (task) task.isDownloading = payload.isDownloading;
  },

  setPlugsCheckerTaskStatus(state: PlugsCheckerTaskState, payload: { id: number, status: string}) {
      const task = state.tasks.find((item: IPlugsCheckerTask) => item.id === payload.id);
      if (task) task.status = payload.status;
  },

  setPlugsCheckerTasksStat(state: PlugsCheckerTaskState, payload: IPlugsCheckerTasksStatData) {
    if (payload.tasks)
      state.tasks = state.tasks.map((t: IPlugsCheckerTask) => {
        const taskStat = payload.tasks.find((s: IPlugsCheckerOneTaskStat) =>  t.id === s.task_id);
        if (taskStat) {
          t.status = taskStat.status;
          t.datetime_end = taskStat.datetime_end;

          const prev = t.stats?.prev;
          const prev_time = t.stats?.prev_time;

          t.stats = taskStat.stats;

          // записыаем стартовые значения количества найденных данных и времени
          // для расчета времени до завершения парсинга
          if (t.stats) {
            t.stats.prev = prev;
            t.stats.prev_time = prev_time;

            const curTime = new Date();

            if (t.stats.found_count > 0) {
              if (t.stats.prev === undefined)
                t.stats.prev = t.stats.found_count;

              if (t.stats.prev_time === undefined)
                t.stats.prev_time = curTime;
            }
          }

          if (taskStat.result)
            t.result = `${apiUrl}/api/v1/plugs-checker-tasks/result/${taskStat.result}`;
        }
        return t;
      });
  },

};

const { commit } = getStoreAccessors<PlugsCheckerTaskState, State>('');

export const commitSetPlugsCheckerTask = commit(mutations.setPlugsCheckerTask);
export const commitSetPlugsCheckerTasks = commit(mutations.setPlugsCheckerTasks);
export const commitSetPlugsCheckerTasksStat = commit(mutations.setPlugsCheckerTasksStat);
export const commitSetPlugsCheckerTaskIsExporting = commit(mutations.setPlugsCheckerTaskIsExporting);
export const commitSetPlugsCheckerTaskIsDownloading = commit(mutations.setPlugsCheckerTaskIsDownloading);
export const commitSetPlugsCheckerTaskStatus = commit(mutations.setPlugsCheckerTaskStatus);
