import Vue from 'vue';
import Router from 'vue-router';

import RouterComponent from './components/RouterComponent.vue';
import FileBrowser from './components/FileBrowser.vue';

Vue.use(Router);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: () => import(/* webpackChunkName: "start" */ './views/main/Start.vue'),
      children: [
        {
          path: 'login',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "login" */ './views/Login.vue'),
        },
        {
          path: 'recover-password',
          component: () => import(/* webpackChunkName: "recover-password" */ './views/PasswordRecovery.vue'),
        },
        {
          path: 'reset-password',
          component: () => import(/* webpackChunkName: "reset-password" */ './views/ResetPassword.vue'),
        },
        {
          path: 'main',
          component: () => import(/* webpackChunkName: "main" */ './views/main/Main.vue'),
          children: [
            {
              path: 'dashboard',
              component: () => import(/* webpackChunkName: "main-dashboard" */ './views/main/Dashboard.vue'),
            },
            {
              path: 'profile',
              component: RouterComponent,
              redirect: 'profile/view',
              children: [
                {
                  path: 'view',
                  component: () => import(
                    /* webpackChunkName: "main-profile" */ './views/main/profile/UserProfile.vue'),
                },
                {
                  path: 'edit',
                  component: () => import(
                    /* webpackChunkName: "main-profile-edit" */ './views/main/profile/UserProfileEdit.vue'),
                },
                {
                  path: 'password',
                  component: () => import(
                    /* webpackChunkName: "main-profile-password" */ './views/main/profile/UserProfileEditPassword.vue'),
                },
              ],
            },
            {
              path: 'admin',
              component: () => import(/* webpackChunkName: "main-admin" */ './views/main/admin/Admin.vue'),
              redirect: 'admin/users/all',
              children: [
                {
                  path: 'users',
                  redirect: 'users/all',
                },
                {
                  path: 'users/all',
                  component: () => import(
                    /* webpackChunkName: "main-admin-users" */ './views/main/admin/AdminUsers.vue'),
                },
                {
                  path: 'users/edit/:id',
                  name: 'main-admin-users-edit',
                  component: () => import(
                    /* webpackChunkName: "main-admin-users-edit" */ './views/main/admin/EditUser.vue'),
                },
                {
                  path: 'users/create',
                  name: 'main-admin-users-create',
                  component: () => import(
                    /* webpackChunkName: "main-admin-users-create" */ './views/main/admin/CreateUser.vue'),
                },
              ],
            },
            {
              path: 'se-tasks',
              component: RouterComponent,
              redirect: 'se-tasks/all',
              children: [
                {
                  path: 'all',
                  component: () => import(/* webpackChunkName: "se-all" */ './views/main/se-tasks/Tasks.vue'),
                },
                {
                  path: 'create',
                  name: 'se-task-create',
                  component: () => import(/* webpackChunkName: "se-create" */ './views/main/se-tasks/CreateTask.vue'),
                },
                {
                  path: 'edit/:id',
                  name: 'se-task-edit',
                  component: () => import(/* webpackChunkName: "se-edit" */ './views/main/se-tasks/EditTask.vue'),
                },
                {
                  path: 'export/:id',
                  name: 'se-task-export',
                  component: () => import(/* webpackChunkName: "se-export" */ './views/main/se-tasks/ExportTask.vue'),
                },
                {
                  path: 'plug/:id',
                  name: 'se-task-plug',
                  component: () => import(/* webpackChunkName: "se-plug" */ './views/main/se-tasks/CreatePlugCheckerTask.vue'),
                },
                {
                  path: 'google',
                  name: 'google-search',
                  component: () => import(/* webpackChunkName: "google-search" */ './views/main/google-search/GoogleSearch.vue'),
                },
              ],
            },
            {
              path: 'google-search',
              component: RouterComponent,
              redirect: 'google-search/all',
              children: [
                {
                  path: 'all',
                  component: () => import(/* webpackChunkName: "google-search" */ './views/main/google-search/GoogleSearch.vue'),
                },
                {
                  path: 'screenshots',
                  component: FileBrowser,
                  props: {
                    listTitle: 'Скриншоты Претензий',
                    workDir: 'google-search',
                    staticDir: 'screenshots',
                    showTooltip: true,
                  },
                },
              ],
            },
            {
              path: 'claim',
              component: RouterComponent,
              redirect: 'claim/all',
              children: [
                {
                  path: 'all',
                  component: () => import(/* webpackChunkName: "claim" */ './views/main/claim/Claim.vue'),
                },
              ],
            },
            {
              path: 'telegram',
              component: RouterComponent,
              redirect: 'telegram/all',
              children: [
                {
                  path: 'all',
                  component: () => import(/* webpackChunkName: "telegram" */ './views/main/telegram/Telegram.vue'),
                },
                {
                  path: 'archive',
                  component: FileBrowser,
                  props: { listTitle: 'Отчеты Telegram', workDir: 'telegram', staticDir: 'tg_results' },
                },
              ],
            },
            {
              path: 'youtube-stat',
              component: RouterComponent,
              redirect: 'youtube-stat/all',
              children: [
                {
                  path: 'all',
                  component: () => import(/* webpackChunkName: "youtube-stat" */ './views/main/youtube-stat/YoutubeStat.vue'),
                },
                {
                  path: 'archive',
                  component: FileBrowser,
                  props: { listTitle: 'Отчеты YouTube...', workDir: 'youtube-stat', staticDir: 'yt_results' },
                },
              ],
            },

            {
              path: 'plugs-checker-tasks',
              component: RouterComponent,
              redirect: 'plugs-checker-tasks/all',
              children: [
                {
                  path: 'all',
                  component: () => import(/* webpackChunkName: "plugs-checker-tasks" */ './views/main/plugs-checker-tasks/PlugsCheckerTasks.vue'),
                },
                {
                  path: 'create',
                  name: 'plugs-checker-tasks-create',
                  component: () => import(/* webpackChunkName: "plugs-checker-create" */ './views/main/plugs-checker-tasks/PlugsCheckerCreateTask.vue'),
                },
                {
                  path: 'edit/:id',
                  name: 'plugs-checker-tasks-edit',
                  component: () => import(
                    /* webpackChunkName: "plugs-checker-tasks-edit" */ './views/main/plugs-checker-tasks/PlugsCheckerEditTask.vue'),
                },
              ],
            },

            {
              path: 'site-parser',
              component: RouterComponent,
              redirect: 'site-parser/all',
              children: [
                {
                  path: 'all',
                  component: () => import(/* webpackChunkName: "site-parser" */ './views/main/site-parser/SiteParser.vue'),
                },
                {
                  path: 'create',
                  name: 'site-parser-create',
                  component: () => import(/* webpackChunkName: "site-parser-create" */ './views/main/site-parser/SiteParserCreate.vue'),
                },
                {
                  path: 'edit/:id',
                  name: 'site-parser-edit',
                  component: () => import(
                    /* webpackChunkName: "site-parser-edit" */ './views/main/site-parser/SiteParserEdit.vue'),
                },
              ],
            },

            {
              path: 'site-parser-template',
              component: RouterComponent,
              redirect: 'site-parser-template/all',
              children: [
                {
                  path: 'all',
                  component: () => import(/* webpackChunkName: "site-parser-template" */ './views/main/site-parser-template/SiteParserTemplate.vue'),
                },
                {
                  path: 'create',
                  name: 'site-parser-template-create',
                  component: () => import(/* webpackChunkName: "site-parser-template-create" */ './views/main/site-parser-template/SiteParserTemplateCreate.vue'),
                },
                {
                  path: 'edit/:domain',
                  name: 'site-parser-template-edit',
                  component: () => import(
                    /* webpackChunkName: "site-parser-template-edit" */ './views/main/site-parser-template/SiteParserTemplateEdit.vue'),
                },
              ],
            },

            {
              path: 'site-traffic',
              component: RouterComponent,
              redirect: 'site-traffic/all',
              children: [
                {
                  path: 'all',
                  component: () => import(/* webpackChunkName: "site-traffic" */ './views/main/site-traffic/SiteTraffic.vue'),
                },
                {
                  path: 'create',
                  name: 'site-traffic-create',
                  component: () => import(/* webpackChunkName: "site-traffic-create" */ './views/main/site-traffic/SiteTrafficCreate.vue'),
                },
                {
                  path: 'edit/:id',
                  name: 'site-traffic-edit',
                  component: () => import(
                    /* webpackChunkName: "site-traffic-edit" */ './views/main/site-traffic/SiteTrafficEdit.vue'),
                },
              ],
            },

            {
              path: 'wordstat',
              component: RouterComponent,
              redirect: 'wordstat/all',
              children: [
                {
                  path: 'all',
                  component: () => import(/* webpackChunkName: "wordstat" */ './views/main/wordstat/Wordstat.vue'),
                },
                {
                  path: 'create',
                  name: 'wordstat-create',
                  component: () => import(/* webpackChunkName: "wordstat-create" */ './views/main/wordstat/WordstatCreate.vue'),
                },
                {
                  path: 'edit/:id',
                  name: 'wordstat-edit',
                  component: () => import(
                    /* webpackChunkName: "wordstat-edit" */ './views/main/wordstat/WordstatEdit.vue'),
                },
              ],
            },

            {
              path: 'registrar',
              component: RouterComponent,
              redirect: 'registrar/all',
              children: [
                {
                  path: 'all',
                  component: () => import(/* webpackChunkName: "registrar" */ './views/main/registrar/Registrar.vue'),
                },
                {
                  path: 'create',
                  name: 'registrar-create',
                  component: () => import(/* webpackChunkName: "registrar-create" */ './views/main/registrar/RegistrarCreate.vue'),
                },
                {
                  path: 'edit/:id',
                  name: 'registrar-edit',
                  component: () => import(
                    /* webpackChunkName: "registrar-edit" */ './views/main/registrar/RegistrarEdit.vue'),
                },
              ],
            },

            {
              path: 'matrix',
              component: RouterComponent,
              redirect: 'matrix/all',
              children: [
                {
                  path: 'all',
                  component: () => import(/* webpackChunkName: "matrix" */ './views/main/matrix/Matrix.vue'),
                },
                {
                  path: 'matrix_results',
                  component: FileBrowser,
                  props: {
                    listTitle: 'Отчеты c новыми матрицами...',
                    workDir: 'matrix',
                    staticDir: 'matrix_results',
                  },
                },
              ],
            },
            {
              path: 'youtube-tasks',
              component: RouterComponent,
              redirect: 'youtube-tasks/all',
              children: [
                {
                  path: 'all',
                  component: () => import(/* webpackChunkName: "youtube-tasks" */ './views/main/youtube-tasks/YtTasks.vue'),
                },
                {
                  path: 'create',
                  name: 'youtube-tasks-create',
                  component: () => import(/* webpackChunkName: "youtube-tasks-create" */ './views/main/youtube-tasks/YtCreateTask.vue'),
                },
                {
                  path: 'edit/:id',
                  name: 'youtube-tasks-edit',
                  component: () => import(/* webpackChunkName: "youtube-tasks-edit" */ './views/main/youtube-tasks/YtEditTask.vue'),
                },
              ],
            },
            {
              path: 'tg-tasks',
              component: RouterComponent,
              redirect: 'tg-tasks/all',
              children: [
                {
                  path: 'all',
                  component: () => import(/* webpackChunkName: "tg-tasks" */ './views/main/tg-tasks/TgTasks.vue'),
                },
                {
                  path: 'create',
                  name: 'tg-tasks-create',
                  component: () => import(/* webpackChunkName: "tg-tasks-create" */ './views/main/tg-tasks/TgCreateTask.vue'),
                },
                {
                  path: 'edit/:id',
                  name: 'tg-tasks-edit',
                  component: () => import(
                    /* webpackChunkName: "tg-tasks-edit" */ './views/main/tg-tasks/TgEditTask.vue'),
                },
              ],
            },
            {
              path: 'avito-tasks',
              component: RouterComponent,
              redirect: 'avito-tasks/all',
              children: [
                {
                  path: 'all',
                  component: () => import(/* webpackChunkName: "avito-tasks" */ './views/main/avito-tasks/AvitoTasks.vue'),
                },
                {
                  path: 'create',
                  name: 'avito-tasks-create',
                  component: () => import(/* webpackChunkName: "avito-tasks-create" */ './views/main/avito-tasks/AvitoCreateTask.vue'),
                },
                {
                  path: 'edit/:id',
                  name: 'avito-tasks-edit',
                  component: () => import(
                    /* webpackChunkName: "avito-tasks-edit" */ './views/main/avito-tasks/AvitoEditTask.vue'),
                },
              ],
            },
            {
              path: 'rkn-checker',
              component: RouterComponent,
              redirect: 'rkn-checker/all',
              children: [
                {
                  path: 'all',
                  component: () => import(/* webpackChunkName: "rkn-checker" */ './views/main/rkn-checker/RknChecker.vue'),
                },
                {
                  path: 'rkn_checker_results',
                  component: FileBrowser,
                  props: {
                    listTitle: 'Отчеты после проверки в РКН...',
                    workDir: 'rkn-checker',
                    staticDir: 'rkn_checker_results',
                  },
                },
              ],
            },

          ],
        },
      ],
    },
    {
      path: '/*', redirect: '/',
    },
  ],
});
