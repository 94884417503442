import { SiteParserTemplateState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';
import { ISiteTemplate } from '@/interfaces/site-parser-template';

export const mutations = {
  setSiteParserTemplates(state: SiteParserTemplateState, payload: ISiteTemplate[]) {
    state.templates = [...payload];
  },
  setSiteParserTemplate(state: SiteParserTemplateState, payload: ISiteTemplate) {
    // самый быстрый вариант
    const newList: ISiteTemplate[] = state.templates.slice();
    for (let i = 0; i < newList.length; i++)
      if (newList[i].domain === payload.domain) {
        newList[i] = payload;
        break;
      }
    state.templates = newList;
  },
  deleteSiteParserTemplate(state: SiteParserTemplateState, payload: { domain: string }) {
    state.templates = state.templates.filter((template: ISiteTemplate) => template.domain !== payload.domain);
  },

};

const { commit } = getStoreAccessors<SiteParserTemplateState, State>('');

export const commitSetSiteParserTemplate = commit(mutations.setSiteParserTemplate);
export const commitSetSiteParserTemplates = commit(mutations.setSiteParserTemplates);
export const commitDeleteSiteParserTemplate = commit(mutations.deleteSiteParserTemplate);
