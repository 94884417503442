import { mutations } from './mutations';
import { getters } from './getters';
import { actions } from './actions';
import { WordstatTaskState } from './state';

const defaultState: WordstatTaskState = {
  tasks: [],
};

export const wordstatTaskModule = {
  state: defaultState,
  mutations,
  actions,
  getters,
};
