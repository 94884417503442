









































































import { Component, Vue } from 'vue-property-decorator';
import { apiUrl as apiUrlExt} from '@/env';
import { readBrowserFiles } from '@/store/file-browser/getters';
import { dispatchDelBrowserFile, dispatchGetBrowserFiles } from '@/store/file-browser/actions';

@Component({
  props: {
    listTitle: String,
    workDir: String,
    staticDir: String,
    showTooltip: {
      type: Boolean,
      default: false,
    },
  },
})
export default class FileBrowser extends Vue {
  public apiUrl = apiUrlExt;

  public headers = [
    {
      text: 'Наименование',
      sortable: true,
      value: 'filename',
      align: 'left',
      groupable: false,
    },
    {
      text: 'Дата',
      sortable: true,
      value: 'modified_date',
      align: 'left',
    },
    {
      text: 'Год',
      sortable: true,
      value: 'year',
      align: 'left',
    },
    {
      text: 'Месяц',
      sortable: true,
      value: 'month',
      align: 'left',
    },
    {
      text: 'Удалить',
      sortable: true,
      value: 'id',
      align: 'left',
    },
  ];

  public search = '';

  public rowPageText = 'Строк на странице';

  public updateTimer?: number = undefined;

  get reportFiles() {
    return readBrowserFiles(this.$store)(this.$props.workDir);
  }

  public tableLoaded = true;

  public async mounted() {
    dispatchGetBrowserFiles(this.$store, this.$props.workDir);
  }

  public cancel() {
    this.$router.back();
  }

  public async handleDelete(filename: string) {
    await dispatchDelBrowserFile(this.$store, { workDir: this.$props.workDir, filename });
  }
}
