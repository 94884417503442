import { RknCheckerState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';
import { IRknCheckerState } from '@/interfaces/rkn-checker';


export const mutations = {
  setRknCheckerFinished(state: RknCheckerState, payload: boolean) {
    state.finished = payload;
  },
  setRknCheckerState(state: RknCheckerState, payload: IRknCheckerState) {
    state.state = payload;
  },
};

const { commit } = getStoreAccessors<RknCheckerState, State>('');

export const commitSetRknCheckerFinished = commit(mutations.setRknCheckerFinished);
export const commitSetRknCheckerState = commit(mutations.setRknCheckerState);
