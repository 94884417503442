import { getStoreAccessors } from 'typesafe-vuex';

import { IYoutubeStatState } from '@/interfaces/youtube-stat';
import { State } from '../state';
import { YoutubeStatState } from './state';

export const mutations = {
  setYoutubeStatFinished(state: YoutubeStatState, payload: boolean) {
    state.finished = payload;
  },
  setYoutubeStatState(state: YoutubeStatState, payload: IYoutubeStatState) {
    state.state = payload;
  },
};

const { commit } = getStoreAccessors<YoutubeStatState, State>('');

export const commitSetYoutubeStatFinished = commit(mutations.setYoutubeStatFinished);
export const commitSetYoutubeStatState = commit(mutations.setYoutubeStatState);
