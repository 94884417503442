import { api } from '@/api';
import { ActionContext } from 'vuex';
import { State } from '../state';
import { SeTaskState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import {
  commitSetSeTasks,
  commitSetSeTask,
  commitSetSeTasksStat,
  commitSetSeTaskIsExporting,
  commitSetSeTaskStatus,
  commitSetSeTaskIsDownloading,
 } from './mutations';
import { dispatchCheckApiError } from '../main/actions';
import { commitAddNotification, commitRemoveNotification } from '../main/mutations';
import {
  ISeTaskCreate,
  ISeTasksStatFilter,
  ISeTaskUpdate,
} from '@/interfaces/se-tasks';

import { apiUrl } from '@/env';
import axios from 'axios';
import { delay } from '@/utils';
import { readOneSeTask } from './getters';
import { API_DOMAIN } from '@/constants';

type MainContext = ActionContext<SeTaskState, State>;

const MAX_FAILED_ATTEMPTS = 2 * 60; // 60 minutes
const ATTEMPTS_INTERVAL = 30 * 1000;  // 30 seconds
const API_URL = apiUrl + API_DOMAIN;
const DOWNLOAD_ONCE = true;

// to set default value to a missing item in an object
const withDefaultValue = (target: object, defaultValue = 0) => new Proxy(target, {
  get: (obj, prop) => prop in obj ? obj[prop] : defaultValue,
});

const authHeaders = (token: string) => ({ headers: {Authorization: `Bearer ${token}`} });

const makeReportFileName = (oldName: string, dateFrom: string, dateTo: string) => {
  const taskName = oldName.split('.')[0].split('-')[0];
  const dateFromFormatted = dateFrom.replace(/-/g, '');
  const dateToFormatted = dateTo.replace(/-/g, '');
  return `${taskName}-${dateFromFormatted}_${dateToFormatted}.zip`;
};

const downloadFile = (fileContent: Blob, fileName: string) => {
  const link = document.createElement('a');
  link.href = URL.createObjectURL(fileContent);
  link.setAttribute('download', fileName);
  link.click();
  URL.revokeObjectURL(link.href);
};

const utils = {
  counter: withDefaultValue({}),  // to control the number of failed attempts

  async downloadSeTaskResult(context: MainContext, payload: { id: number, result: string }, once = false) {
    const url = `${API_URL}/se-tasks/${payload.id}/result`;
    const { token } = context.rootState.main;
    const { id, result } = payload;

    const updateStatuses = () => {
      commitSetSeTaskIsExporting(context, { id, isExporting: false});
      commitSetSeTaskIsDownloading(context, { id, isDownloading: false});
      commitSetSeTaskStatus(context, { id, status: 'ENDED' });
    };

    if (once) {
      commitSetSeTaskIsDownloading(context, { id, isDownloading: true });
      commitSetSeTaskStatus(context, { id, status: 'DOWNLOAD' });
    } else {
      const updatedTask = readOneSeTask(context)(id);
      if (updatedTask?.status !== 'EXPORT' && !updatedTask?.result?.endsWith('.zip')) {
        delete this.counter[id];
        return;
      }
      if (this.counter[id] === 0) commitSetSeTaskIsExporting(context, { id, isExporting: true });
    }

    // на случай, если захотим брать название файла отчта из store
    // const result = context.state.se_tasks.find((seTask => seTask.id === payload.id))?.result;
    // console.log('task.result --> ', result);

    try {
      const response = await fetch(url, authHeaders(token));
      if (response.status !== 200) throw new Error(`Response status: ${response.status}`);
      const bytes = await response.blob();
      delete this.counter[id];
      downloadFile(bytes, result);
      commitAddNotification(context, { content: 'Файл отчета успешно загружен', color: 'success' });
      updateStatuses();
    } catch (error) {
      if (once) {
        // console.log('Ошибка загрузки файла:', error);
        commitAddNotification(context, {
          content: 'Ошибка загрузки файла',
          color: 'error',
        });
        updateStatuses();
      } else if (this.counter[id] < MAX_FAILED_ATTEMPTS) {
          setTimeout(() => this.downloadSeTaskResult(context, payload), ATTEMPTS_INTERVAL);
          this.counter[id]++;
        } else {
          delete this.counter[id];
          commitAddNotification(context, {
            content: 'Превышено максимальное количество попыток скачивания файла. Операция отменена',
            color: 'error' });
          updateStatuses();
        }
    }
  },
};


export const actions = {
  async actionGetSeTasks(context: MainContext) {
    try {
      const { data } = await api.getSeTasks(context.rootState.main.token);
      commitSetSeTasks(context, data);
    } catch (error) {
      if (axios.isAxiosError(error)) await dispatchCheckApiError(context, error);
    }
  },

  async actionUpdateSeTask(context: MainContext, payload: { id: number, task: ISeTaskUpdate }) {
    const { token } = context.rootState.main;
    const { id, task } = payload;
    try {
      const loadingNotification = { content: 'Сохранение', showProgress: true };
      commitAddNotification(context, loadingNotification);
      const [{ data }] = await Promise.all([api.updateSeTask(token, id, task), delay(500)]);
      commitSetSeTask(context, data);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, { content: 'Задача успешно обновлена', color: 'success' });
    } catch (error) {
      if (axios.isAxiosError(error)) await dispatchCheckApiError(context, error);
    }
  },

  async actionCreateSeTask(context: MainContext, payload: ISeTaskCreate) {
    const { token } = context.rootState.main;
    try {
      const loadingNotification = { content: 'Создание', showProgress: true };
      commitAddNotification(context, loadingNotification);
      const [{ data }] = await Promise.all([api.createSeTask(token, payload), delay(500)]);
      commitSetSeTask(context, data);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, { content: 'Задача успешно создана', color: 'success' });
    } catch (error) {
      if (axios.isAxiosError(error)) await dispatchCheckApiError(context, error);
    }
  },

  async actionDeleteSeTask(context: MainContext, payload: { id: number }) {
    const { token } = context.rootState.main;
    try {
      const loadingNotification = { content: 'Удаление', showProgress: true };
      commitAddNotification(context, loadingNotification);
      const [{ data }] = await Promise.all([api.deleteSeTask(token, payload.id), delay(500)]);
      commitSetSeTask(context, data);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, { content: 'Задача успешно удалена', color: 'success' });
    } catch (error) {
      if (axios.isAxiosError(error)) await dispatchCheckApiError(context, error);
    }
  },

  async actionRunSeTask(context: MainContext, payload: { id: number }) {
    const { token } = context.rootState.main;
    const { id } = payload;
    try {
      const loadingNotification = { content: 'Запуск', showProgress: true };
      commitAddNotification(context, loadingNotification);
      await Promise.all([api.runSeTask(token, id), delay(500)]);
      commitSetSeTaskStatus(context, { id, status: 'ACTIVE' });
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, { content: 'Задача успешно запущена', color: 'success' });
    } catch (error) {
      if (axios.isAxiosError(error)) await dispatchCheckApiError(context, error);
    }
  },

  async actionKillSeTask(context: MainContext, payload: { id: number }) {
    const { token } = context.rootState.main;
    try {
      const loadingNotification = { content: 'Прерывание', showProgress: true };
      commitAddNotification(context, loadingNotification);
      const [{ data }] = await Promise.all([api.killSeTask(token, payload.id), delay(500)]);
      commitSetSeTask(context, data);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, { content: 'Задача успешно прервана', color: 'success' });
    } catch (error) {
      if (axios.isAxiosError(error)) await dispatchCheckApiError(context, error);
    }
  },

  async actionKillExportSeTask(context: MainContext, payload: { id: number }) {
    const { token } = context.rootState.main;
    try {
      const loadingNotification = { content: 'Прерывание' };
      commitAddNotification(context, loadingNotification);
      const [{ data }] = await Promise.all([api.killExportSeTask(token, payload.id), delay(500)]);
      commitSetSeTask(context, data);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, { content: 'Экпорт успешно прерван', color: 'success' });
    } catch (error) {
      if (axios.isAxiosError(error)) await dispatchCheckApiError(context, error);
    }
  },


  async actionStatSeTasks(context: MainContext, payload: { filter: ISeTasksStatFilter }) {
    const { token } = context.rootState.main;
    try {
      const { data } = await api.statSeTasks(token, payload.filter);
      commitSetSeTasksStat(context, data);
    } catch (error) {
      if (axios.isAxiosError(error)) await dispatchCheckApiError(context, error);
    }
  },

  // загружаем файл отчета
  async actionDownloadSeTaskResult(context: MainContext, payload: { id: number, result: string }) {
    utils.downloadSeTaskResult(context, payload, DOWNLOAD_ONCE);
  },

  // экспортируем результаты парсинга
  async actionExportSeTask(
    context: MainContext, payload: { id: number, date_from: string, date_to: string, result: string },
  ) {
    const { token } = context.rootState.main;
    const { id, date_from, date_to, result } = payload;

    // собираем название нового файла отчета, чтобы не доставать его с бэкенда или store отдельным запросом
    payload.result = makeReportFileName(result, date_from, date_to);

    try {
      const response = await api.exportSeTask(token, id, date_from, date_to);
      if (response) {
        commitAddNotification(
          context,
          {
            content: 'Экспортируем отчет. Это может занять какое-то время. По окончании, отчет будет загружен автоматически',
            color: 'success',
        });
        setTimeout(() => utils.downloadSeTaskResult(context, payload), ATTEMPTS_INTERVAL);
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        commitAddNotification(
          context,
          {
            content: 'Что-то пошло не так (( Возможно отчет уже экспортируется другим пользователем. Попробуйте позже',
            color: 'error',
        });
        await dispatchCheckApiError(context, error);
      }
    }
  },
};

const { dispatch } = getStoreAccessors<SeTaskState, State>('');

export const dispatchCreateSeTask = dispatch(actions.actionCreateSeTask);
export const dispatchGetSeTasks = dispatch(actions.actionGetSeTasks);
export const dispatchUpdateSeTask = dispatch(actions.actionUpdateSeTask);
export const dispatchDeleteSeTask = dispatch(actions.actionDeleteSeTask);
export const dispatchRunSeTask = dispatch(actions.actionRunSeTask);
export const dispatchKillSeTask = dispatch(actions.actionKillSeTask);
export const dispatchStatSeTasks = dispatch(actions.actionStatSeTasks);
export const dispatchExportSeTask = dispatch(actions.actionExportSeTask);
export const dispatchDownloadSeTaskResult = dispatch(actions.actionDownloadSeTaskResult);
export const dispatchKillExportSeTask = dispatch(actions.actionKillExportSeTask);
