import { AxiosError } from 'axios';
import { getStoreAccessors } from 'typesafe-vuex';
import { ActionContext } from 'vuex';

import { api } from '@/api';
import { API_DOMAIN } from '@/constants';
import { apiUrl } from '@/env';
import { delay } from '@/utils';
import { dispatchCheckApiError } from '../main/actions';
import { commitAddNotification, commitRemoveNotification } from '../main/mutations';
import { State } from '../state';
import {
  commitDeleteSiteParserTemplate, commitSetSiteParserTemplate, commitSetSiteParserTemplates,
} from './mutations';
import { SiteParserTemplateState } from './state';
import { ISiteTemplateCreate, ISiteTemplateUpdate } from '@/interfaces/site-parser-template';

type MainContext = ActionContext<SiteParserTemplateState, State>;

export const actions = {
  async actionGetSiteParserTemplates(context: MainContext) {
    try {
      const { data } = await api.getSiteParserTemplates(context.rootState.main.token);
      commitSetSiteParserTemplates(context, data);
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },

  async actionUpdateSiteParserTemplate(
    context: MainContext,
    payload: { domain: string, template: ISiteTemplateUpdate },
    ) {
    const { token } = context.rootState.main;
    const { domain, template } = payload;
    try {
      const loadingNotification = { content: 'Сохранение', showProgress: true };
      commitAddNotification(context, loadingNotification);
      const [{ data }] = await Promise.all([api.updateSiteParserTemplate(token, domain, template), delay(500)]);
      commitSetSiteParserTemplate(context, data);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, { content: 'Шаблон успешно обновлен', color: 'success' });
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },

  async actionCreateSiteParserTemplate(context: MainContext, payload: ISiteTemplateCreate) {
    const { token } = context.rootState.main;
    try {
      const loadingNotification = { content: 'Создание', showProgress: true };
      commitAddNotification(context, loadingNotification);
      const [{ data }] = await Promise.all([api.createSiteParserTemplate(token, payload), delay(500)]);
      commitSetSiteParserTemplate(context, data);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, { content: 'Шаблон успешно создан', color: 'success' });
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },

  async actionDeleteSiteParserTemplate(context: MainContext, payload: { domain: string }) {
    const { token } = context.rootState.main;
    try {
      const loadingNotification = { content: 'Удаление', showProgress: true };
      commitAddNotification(context, loadingNotification);
      await Promise.all([api.deleteSiteParserTemplate(token, payload.domain), delay(500)]);
      commitDeleteSiteParserTemplate(context, payload);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, { content: 'Шаблон успешно удален', color: 'success' });
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },
};

const { dispatch } = getStoreAccessors<SiteParserTemplateState, State>('');

export const dispatchCreateSiteParserTemplate = dispatch(actions.actionCreateSiteParserTemplate);
export const dispatchGetSiteParserTemplates = dispatch(actions.actionGetSiteParserTemplates);
export const dispatchUpdateSiteParserTemplate = dispatch(actions.actionUpdateSiteParserTemplate);
export const dispatchDeleteSiteParserTemplate = dispatch(actions.actionDeleteSiteParserTemplate);
