import { mutations } from './mutations';
import { getters } from './getters';
import { actions } from './actions';
import { SiteParserTaskState } from './state';

const defaultState: SiteParserTaskState = {
  tasks: [],
  domains: [],
};

export const siteParserTaskModule = {
  state: defaultState,
  mutations,
  actions,
  getters,
};
