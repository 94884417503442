import { MatrixState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';
import { IMatrixState } from '@/interfaces/matrix';


export const mutations = {
  setMatrixFinished(state: MatrixState, payload: boolean) {
    state.finished = payload;
  },
  setMatrixState(state: MatrixState, payload: IMatrixState) {
    state.state = payload;
  },
};

const { commit } = getStoreAccessors<MatrixState, State>('');

export const commitSetMatrixFinished = commit(mutations.setMatrixFinished);
export const commitSetMatrixState = commit(mutations.setMatrixState);
