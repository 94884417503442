import axios, { AxiosError } from 'axios';
import { getStoreAccessors } from 'typesafe-vuex';
import { ActionContext } from 'vuex';

import { api } from '@/api';
import { dispatchCheckApiError } from '../main/actions';
import {
  commitSetClaimFinished,
  commitSetClaimState,
} from './mutations';
import { ClaimState } from './state';
import { State } from '../state';

type MainContext = ActionContext<ClaimState, State>;

export const actions = {
  async actionGetClaimStatus(context: MainContext) {
    try {
        const { data } = await api.getClaimStatus(context.rootState.main.token);
        commitSetClaimState(context, data);
        return data;
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },
  async actionRunClaim(context: MainContext) {
      try {
          const { data } = await api.runClaim(context.rootState.main.token);
          return data;
      } catch (error) {
        await dispatchCheckApiError(context, error as AxiosError);
      }
  },
  async actionCancelClaim(context: MainContext) {
    try {
      await api.cancelClaim(context.rootState.main.token);
      commitSetClaimFinished(context, true);
      return true;
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },
};

const { dispatch } = getStoreAccessors<ClaimState, State>('');

export const dispatchGetClaimStatus = dispatch(actions.actionGetClaimStatus);
export const dispatchRunClaim = dispatch(actions.actionRunClaim);
export const dispatchCancelClaim = dispatch(actions.actionCancelClaim);
