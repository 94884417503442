import { YtTaskState } from './state';
import { apiUrl } from '@/env';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';
import { IYtTask, IYtOneTaskStat, IYtTasksStatData } from '@/interfaces/yt-tasks';


export const mutations = {
  setYtTasks(state: YtTaskState, payload: IYtTask[]) {
    // добавляем флаг isExporting; его пока нет в PG
    // если isExporting = true, иконка загрузки файлов заблокирована
    payload.forEach((item) => {
      item.isExporting = state.yt_tasks.find((task) => task.id === item.id)?.isExporting;
      item.isDownloading = state.yt_tasks.find((task) => task.id === item.id)?.isDownloading;
      if (item.status === 'ACTIVE') {
        const taskActive = state.yt_tasks.find((t) => t.id === item.id);
        if (taskActive && taskActive.stats) item.stats = taskActive.stats;
        if (taskActive && taskActive.extra_stats) item.extra_stats = taskActive.extra_stats;
      }
      if (item.isExporting) item.status = 'EXPORT';
      else if (item.isDownloading) item.status = 'DOWNLOAD';
    });
    state.yt_tasks = payload;
  },
  setYtTask(state: YtTaskState, payload: IYtTask) {

    // const newList: IYtTask[] = state.yt_tasks.slice();
    // const index = newList.findIndex(item => item.id === payload.id);
    // if (index !== -1) {
    //     newList[index] = payload;
    //     state.yt_tasks = newList;
    // };

    // самый быстрый вариант
    const newList: IYtTask[] = state.yt_tasks.slice();
    for (let i = 0; i < newList.length; i++)
      if (newList[i].id === payload.id) {
        newList[i] = payload;
        break;
      }
    state.yt_tasks = newList;
  },

  // изменяем флаг isExporting
  setYtTaskIsExporting(state: YtTaskState, payload: { id: number, isExporting: boolean}) {
      const task = state.yt_tasks.find((item: IYtTask) => item.id === payload.id);
      if (task) task.isExporting = payload.isExporting;
  },

  // изменяем флаг isDownloading
  setYtTaskIsDownloading(state: YtTaskState, payload: { id: number, isDownloading: boolean}) {
      const task = state.yt_tasks.find((item: IYtTask) => item.id === payload.id);
      if (task) task.isDownloading = payload.isDownloading;
  },

  setYtTaskStatus(state: YtTaskState, payload: { id: number, status: string}) {
      const task = state.yt_tasks.find((item: IYtTask) => item.id === payload.id);
      if (task) task.status = payload.status;
  },

  setYtTasksStat(state: YtTaskState, payload: IYtTasksStatData) {
    if (payload.tasks)
      state.yt_tasks = state.yt_tasks.map((t: IYtTask) => {
        const taskStat = payload.tasks.find((s: IYtOneTaskStat) =>  t.id === s.task_id);
        if (taskStat) {
          t.status = taskStat.status;
          t.datetime_end = taskStat.datetime_end;

          const prev = t.stats?.prev;
          const prev_time = t.stats?.prev_time;

          t.stats = taskStat.stats;

          // записыаем стартовые значения количества найденных данных и времени
          // для расчета времени до завершения парсинга
          if (t.stats) {
            t.stats.prev = prev;
            t.stats.prev_time = prev_time;

            const curTime = new Date();

            if (t.stats.found_count > 0) {
              if (t.stats.prev === undefined)
                t.stats.prev = t.stats.found_count;

              if (t.stats.prev_time === undefined)
                t.stats.prev_time = curTime;
            }
          }

          t.extra_stats = taskStat.extra_stats;

          // пишем статистику по записи текстов книг в elastic
          t.text_fetch_found_count = taskStat.text_fetch_found_count;
          t.text_fetch_total_count = taskStat.text_fetch_total_count;

          // пишем статистику по чтению white-листа
          t.legal_urls_found_count = taskStat.legal_urls_found_count;
          t.legal_urls_total_count = taskStat.legal_urls_total_count;

          if (taskStat.result)
            t.result = `${apiUrl}/api/v1/yt-tasks/result/${taskStat.result}`;
        }
        return t;
      });
  },

};

const { commit } = getStoreAccessors<YtTaskState, State>('');

export const commitSetYtTask = commit(mutations.setYtTask);
export const commitSetYtTasks = commit(mutations.setYtTasks);
export const commitSetYtTasksStat = commit(mutations.setYtTasksStat);
export const commitSetYtTaskIsExporting = commit(mutations.setYtTaskIsExporting);
export const commitSetYtTaskIsDownloading = commit(mutations.setYtTaskIsDownloading);
export const commitSetYtTaskStatus = commit(mutations.setYtTaskStatus);
