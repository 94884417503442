import { getStoreAccessors } from 'typesafe-vuex';

import { PlugsCheckerTaskState } from './state';
import { State } from '../state';
import { IPlugsCheckerTask } from '@/interfaces/plugs-checker-tasks';

export const getters = {
  PlugsCheckerTasks: (state: PlugsCheckerTaskState) => state.tasks,
  onePlugsCheckerTask: (state: PlugsCheckerTaskState) => (taskId: number) => state.tasks.find(
    (task: IPlugsCheckerTask) => task.id === taskId),
};

const { read } = getStoreAccessors<PlugsCheckerTaskState, State>('');

export const readOnePlugsCheckerTask = read(getters.onePlugsCheckerTask);
export const readPlugsCheckerTasks = read(getters.PlugsCheckerTasks);
