export interface IYoutubeStatReportFile {
  id: number;
  filename: string;
  modified_date: string;
  year?: number;
  month?: number;
}

export interface IYoutubeStatState {
  status: number | null;
  total: number | null;
  done: number | null;
}

export const youtubeStatInitialState: IYoutubeStatState = {
  status: null,
  total: null,
  done: null,
};
