import { getStoreAccessors } from 'typesafe-vuex';

import { AvitoTaskState } from './state';
import { State } from '../state';
import { IAvitoTask } from '@/interfaces/avito-tasks';

export const getters = {
  AvitoTasks: (state: AvitoTaskState) => state.tasks,
  oneAvitoTask: (state: AvitoTaskState) => (taskId: number) => state.tasks.find(
    (task: IAvitoTask) => task.id === taskId),
};

const { read } = getStoreAccessors<AvitoTaskState, State>('');

export const readOneAvitoTask = read(getters.oneAvitoTask);
export const readAvitoTasks = read(getters.AvitoTasks);
