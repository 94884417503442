import { AxiosError } from 'axios';
import { getStoreAccessors } from 'typesafe-vuex';
import { ActionContext } from 'vuex';

import { api } from '@/api';
import { IUserProfileCreate, IUserProfileUpdate } from '@/interfaces/users';
import { delay } from '../../utils';
import { dispatchCheckApiError } from '../main/actions';
import { commitAddNotification, commitRemoveNotification } from '../main/mutations';
import { State } from '../state';
import { commitSetUser, commitSetUsers } from './mutations';
import { AdminState } from './state';

type MainContext = ActionContext<AdminState, State>;

export const actions = {
  async actionGetUsers(context: MainContext) {
    try {
      const { data } = await api.getUsers(context.rootState.main.token);
      commitSetUsers(context, data);
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },
  async actionUpdateUser(context: MainContext, payload: { id: number, user: IUserProfileUpdate }) {
    try {
      const loadingNotification = { content: 'Cохранение' };
      commitAddNotification(context, loadingNotification);
      const [{ data }] = await Promise.all([
        api.updateUser(
          context.rootState.main.token,
          payload.id,
          payload.user,
        ),
        delay(500),
      ]);
      commitSetUser(context, data);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, { content: 'Пользователь успешно обновлен', color: 'success' });
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },
  async actionCreateUser(context: MainContext, payload: IUserProfileCreate) {
    try {
      const loadingNotification = { content: 'saving', showProgress: true };
      commitAddNotification(context, loadingNotification);
      const [{ data }] = await Promise.all([
        api.createUser(context.rootState.main.token, payload),
        delay(500),
      ]);
      commitSetUser(context, data);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, { content: 'User successfully created', color: 'success' });
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },
};

const { dispatch } = getStoreAccessors<AdminState, State>('');

export const dispatchCreateUser = dispatch(actions.actionCreateUser);
export const dispatchGetUsers = dispatch(actions.actionGetUsers);
export const dispatchUpdateUser = dispatch(actions.actionUpdateUser);
