import axios from 'axios';
import { getStoreAccessors } from 'typesafe-vuex';
import { ActionContext } from 'vuex';

import { api } from '@/api';
import { dispatchCheckApiError } from '../main/actions';
import {
  commitSetGoogleSearchFinished,
  commitSetGoogleSearchState,
} from './mutations';
import { GoogleSearchScreenshotsState } from './state';
import { State } from '../state';

type MainContext = ActionContext<GoogleSearchScreenshotsState, State>;

export const actions = {
  async actionGetGoogleSearchStatus(context: MainContext) {
    try {
        const { data } = await api.getGoogleSearchStatus(context.rootState.main.token);
        commitSetGoogleSearchState(context, data);
        return data;
    } catch (error) {
      if (axios.isAxiosError(error)) await dispatchCheckApiError(context, error);
    }
  },
  async actionRunGoogleSearch(context: MainContext) {
      try {
          const { data } = await api.runGoogleSearch(context.rootState.main.token);
          return data;
      } catch (error) {
        if (axios.isAxiosError(error)) await dispatchCheckApiError(context, error);
      }
  },
  async actionCancelGoogleSearch(context: MainContext) {
    try {
      await api.cancelGoogleSearch(context.rootState.main.token);
      commitSetGoogleSearchFinished(context, true);
      return true;
    } catch (error) {
      if (axios.isAxiosError(error)) await dispatchCheckApiError(context, error);
    }
  },
};

const { dispatch } = getStoreAccessors<GoogleSearchScreenshotsState, State>('');

export const dispatchGetGoogleSearchStatus = dispatch(actions.actionGetGoogleSearchStatus);
export const dispatchRunGoogleSearch = dispatch(actions.actionRunGoogleSearch);
export const dispatchCancelGoogleSearch = dispatch(actions.actionCancelGoogleSearch);
