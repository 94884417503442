import { mutations } from './mutations';
import { getters } from './getters';
import { actions } from './actions';
import { RegistrarTaskState } from './state';

const defaultState: RegistrarTaskState = {
  tasks: [],
};

export const registrarTaskModule = {
  state: defaultState,
  mutations,
  actions,
  getters,
};
