import { getStoreAccessors } from 'typesafe-vuex';

import { State } from '../state';
import { YoutubeStatState } from './state';

export const getters = {
  youtubeStatFinished: (state: YoutubeStatState) => state.finished,
  youtubeStatState: (state: YoutubeStatState) => state.state,
};

const { read } = getStoreAccessors<YoutubeStatState, State>('');

export const readYoutubeStatFinished = read(getters.youtubeStatFinished);
export const readYoutubeStatState = read(getters.youtubeStatState);
