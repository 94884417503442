import { AxiosError } from 'axios';
import { getStoreAccessors } from 'typesafe-vuex';
import { ActionContext } from 'vuex';

import { api } from '@/api';
import { API_DOMAIN } from '@/constants';
import { apiUrl } from '@/env';
import {
  IYtTaskCreate,
  IYtTaskUpdate,
} from '@/interfaces/yt-tasks';
import { delay } from '@/utils';
import { dispatchCheckApiError } from '../main/actions';
import { commitAddNotification, commitRemoveNotification } from '../main/mutations';
import { State } from '../state';
import { readOneYtTask } from './getters';
import {
  commitSetYtTask, commitSetYtTaskIsDownloading, commitSetYtTasks, commitSetYtTasksStat,
  commitSetYtTaskStatus,
} from './mutations';
import { YtTaskState } from './state';
import { authHeaders, downloadFile, withDefaultValue } from '../store-utils';

type MainContext = ActionContext<YtTaskState, State>;

const MAX_FAILED_ATTEMPTS = 2 * 60; // 60 minutes
const ATTEMPTS_INTERVAL = 30 * 1000;  // 30 seconds
const API_URL = apiUrl + API_DOMAIN;
const DOWNLOAD_ONCE = true;

const utils = {
  counter: withDefaultValue({}),  // to control the number of failed attempts

  async downloadYtTaskResult(context: MainContext, payload: { id: number, result: string }, once = false) {
    const url = `${API_URL}/yt-tasks/${payload.id}/result`;
    const { token } = context.rootState.main;
    const { id, result } = payload;

    const updateStatuses = () => {
      commitSetYtTaskIsDownloading(context, { id, isDownloading: false});
      commitSetYtTaskStatus(context, { id, status: 'ENDED' });
    };

    if (once) {
      commitSetYtTaskIsDownloading(context, { id, isDownloading: true });
      commitSetYtTaskStatus(context, { id, status: 'DOWNLOAD' });
    } else {
      const updatedTask = readOneYtTask(context)(id);
      if (updatedTask?.status !== 'EXPORT' && !updatedTask?.result?.endsWith('.zip')) {
        delete this.counter[id];
        return;
      }
    }

    // на случай, если захотим брать название файла отчта из store
    // const result = context.state.se_tasks.find((YtTask => YtTask.id === payload.id))?.result;
    // console.log('task.result --> ', result);

    try {
      const response = await fetch(url, authHeaders(token));
      if (response.status !== 200) throw new Error(`Response status: ${response.status}`);
      const bytes = await response.blob();
      delete this.counter[id];
      downloadFile(bytes, result);
      commitAddNotification(context, { content: 'Файл отчета успешно загружен', color: 'success' });
      updateStatuses();
    } catch (error) {
      if (once) {
        // console.log('Ошибка загрузки файла:', error);
        commitAddNotification(context, {
          content: 'Ошибка загрузки файла',
          color: 'error',
        });
        updateStatuses();
      } else if (this.counter[id] < MAX_FAILED_ATTEMPTS) {
          setTimeout(() => this.downloadYtTaskResult(context, payload), ATTEMPTS_INTERVAL);
          this.counter[id]++;
        } else {
          delete this.counter[id];
          commitAddNotification(context, {
            content: 'Превышено максимальное количество попыток скачивания файла. Операция отменена',
            color: 'error' });
          updateStatuses();
        }
    }
  },
};


export const actions = {
  async actionGetYtTasks(context: MainContext) {
    try {
      const { data } = await api.getYtTasks(context.rootState.main.token);
      commitSetYtTasks(context, data);
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },

  async actionUpdateYtTask(context: MainContext, payload: { id: number, task: IYtTaskUpdate }) {
    const { token } = context.rootState.main;
    const { id, task } = payload;
    try {
      const loadingNotification = { content: 'Сохранение', showProgress: true };
      commitAddNotification(context, loadingNotification);
      const [{ data }] = await Promise.all([api.updateYtTask(token, id, task), delay(500)]);
      commitSetYtTask(context, data);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, { content: 'Задача успешно обновлена', color: 'success' });
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },

  async actionCreateYtTask(context: MainContext, payload: IYtTaskCreate) {
    const { token } = context.rootState.main;
    try {
      const loadingNotification = { content: 'Создание', showProgress: true };
      commitAddNotification(context, loadingNotification);
      const [{ data }] = await Promise.all([api.createYtTask(token, payload), delay(500)]);
      commitSetYtTask(context, data);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, { content: 'Задача успешно создана', color: 'success' });
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },

  async actionDeleteYtTask(context: MainContext, payload: { id: number }) {
    const { token } = context.rootState.main;
    try {
      const loadingNotification = { content: 'Удаление', showProgress: true };
      commitAddNotification(context, loadingNotification);
      const [{ data }] = await Promise.all([api.deleteYtTask(token, payload.id), delay(500)]);
      commitSetYtTask(context, data);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, { content: 'Задача успешно удалена', color: 'success' });
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },

  async actionRunYtTask(context: MainContext, payload: { id: number }) {
    const { token } = context.rootState.main;
    const { id } = payload;
    try {
      const loadingNotification = { content: 'Запуск', showProgress: true };
      commitAddNotification(context, loadingNotification);
      await Promise.all([api.runYtTask(token, id), delay(500)]);
      commitSetYtTaskStatus(context, { id, status: 'ACTIVE' });
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, { content: 'Задача успешно запущена', color: 'success' });
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },

  async actionKillYtTask(context: MainContext, payload: { id: number }) {
    const { token } = context.rootState.main;
    try {
      const loadingNotification = { content: 'Прерывание', showProgress: true };
      commitAddNotification(context, loadingNotification);
      const [{ data }] = await Promise.all([api.killYtTask(token, payload.id), delay(500)]);
      commitSetYtTask(context, data);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, { content: 'Задача успешно прервана', color: 'success' });
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },

  async actionStatYtTasks(context: MainContext) {
    const { token } = context.rootState.main;
    try {
      const { data } = await api.statYtTasks(token);
      commitSetYtTasksStat(context, data);
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },

  // загружаем файл отчета
  async actionDownloadYtTaskResult(context: MainContext, payload: { id: number, result: string }) {
    utils.downloadYtTaskResult(context, payload, DOWNLOAD_ONCE);
  },

  async actionDownloadYtTaskFile(context: MainContext, payload: { taskFile: string }) {
    const { taskFile } = payload;
    const url = `${API_URL}/yt-tasks/task-file/${taskFile}`;
    const { token } = context.rootState.main;

    try {
      const response = await fetch(url, authHeaders(token));
      if (response.status !== 200) throw new Error(`Response status: ${response.status}`);
      const bytes = await response.blob();
      downloadFile(bytes, taskFile);
      commitAddNotification(context, { content: 'Файл отчета успешно загружен', color: 'success' });
    } catch (error) {
        commitAddNotification(context, {
          content: 'Ошибка загрузки файла',
          color: 'error',
        });
    }

  },
};

const { dispatch } = getStoreAccessors<YtTaskState, State>('');

export const dispatchCreateYtTask = dispatch(actions.actionCreateYtTask);
export const dispatchGetYtTasks = dispatch(actions.actionGetYtTasks);
export const dispatchUpdateYtTask = dispatch(actions.actionUpdateYtTask);
export const dispatchDeleteYtTask = dispatch(actions.actionDeleteYtTask);
export const dispatchRunYtTask = dispatch(actions.actionRunYtTask);
export const dispatchKillYtTask = dispatch(actions.actionKillYtTask);
export const dispatchStatYtTasks = dispatch(actions.actionStatYtTasks);
export const dispatchDownloadYtTaskResult = dispatch(actions.actionDownloadYtTaskResult);
export const dispatchDownloadYtTaskFile = dispatch(actions.actionDownloadYtTaskFile);
