import { mutations } from './mutations';
import { getters } from './getters';
import { actions } from './actions';
import { ClaimState } from './state';
import { claimInitialState } from '@/interfaces/claim';

const defaultState: ClaimState = {
  finished: false,
  state: claimInitialState,
};

export const ClaimModule = {
  state: defaultState,
  mutations,
  actions,
  getters,
};
