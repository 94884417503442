import { mutations } from './mutations';
import { getters } from './getters';
import { actions } from './actions';
import { MatrixState } from './state';
import { matrixInitialState } from '@/interfaces/matrix';

const defaultState: MatrixState = {
  finished: false,
  state: matrixInitialState,
};

export const matrixModule = {
  state: defaultState,
  mutations,
  actions,
  getters,
};
