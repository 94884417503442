export interface IGoogleSearchScreenshot {
    id: number;
    filename: string;
    modified_date: string;
    year?: number;
    month?: number;
}

export interface IGoogleSearchState {
    status: number | null;
    total: number | null;
    done: number | null;
    message?: string;
}

export const googleSearchInitialState: IGoogleSearchState = {
    status: null,
    total: null,
    done: null,
};
