import { AxiosError } from 'axios';
import { getStoreAccessors } from 'typesafe-vuex';
import { ActionContext } from 'vuex';

import { api } from '@/api';
import { dispatchCheckApiError } from '../main/actions';
import {
  commitSetRknCheckerFinished,
  commitSetRknCheckerState,
} from './mutations';
import { RknCheckerState } from './state';
import { State } from '../state';
import { IRknCheckerTaskCreate } from '@/interfaces/rkn-checker';
import { commitAddNotification, commitRemoveNotification } from '../main/mutations';
import { delay } from '@/utils';

type MainContext = ActionContext<RknCheckerState, State>;

export const actions = {
  async actionGetRknCheckerStatus(context: MainContext) {
    try {
        const { data } = await api.getRknCheckerStatus(context.rootState.main.token);
        commitSetRknCheckerState(context, data);
        return data;
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },

  async actionRunRknChecker(context: MainContext, payload: IRknCheckerTaskCreate) {
    const { token } = context.rootState.main;
    try {
      const [{ data }] = await Promise.all([api.runRknChecker(token, payload), delay(500)]);
      return data;
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },

  async actionCancelRknChecker(context: MainContext) {
    try {
      await api.cancelRknChecker(context.rootState.main.token);
      commitSetRknCheckerFinished(context, true);
      return true;
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },
};

const { dispatch } = getStoreAccessors<RknCheckerState, State>('');

export const dispatchGetRknCheckerStatus = dispatch(actions.actionGetRknCheckerStatus);
export const dispatchRunRknChecker = dispatch(actions.actionRunRknChecker);
export const dispatchCancelRknChecker = dispatch(actions.actionCancelRknChecker);
