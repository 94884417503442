import { mutations } from './mutations';
import { getters } from './getters';
import { actions } from './actions';
import { GoogleSearchScreenshotsState } from './state';
import { googleSearchInitialState } from '@/interfaces/google-search';

const defaultState: GoogleSearchScreenshotsState = {
  finished: false,
  state: googleSearchInitialState,
};

export const googleSearchModule = {
  state: defaultState,
  mutations,
  actions,
  getters,
};
